import { FC, MouseEvent } from 'react'
import SVG from 'react-inlinesvg'

import { Memo } from '@/hoc/Memo'

export interface IIcon {
  name?: string
  fill?: string
  stroke?: string
  className?: string
  classNameSpan?: string
  width?: number
  height?: number
  path?: string
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void
}

export const Icon: FC<IIcon> = Memo(
  ({ name, onClick, path = 'icons', classNameSpan, ...props }: IIcon) => {
    const p = `${path}/${name}`
    const src = `/img/${p}.svg`

    return (
      <>
        {onClick ? (
          <span onClick={onClick} className={classNameSpan}>
            <SVG
              // onError={error => console.log(error.message)}
              // onLoad={(src, hasCache) => console.log(src, hasCache)}
              // preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              src={src}
              {...props}
            />
          </span>
        ) : (
          <SVG
            // onError={error => console.log(error.message)}
            // onLoad={(src, hasCache) => console.log(src, hasCache)}
            // preProcessor={code => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
            src={src}
            {...props}
          />
        )}
      </>
    )
  },
)
