import { cn } from '@bem-react/classname'
import { FC } from 'react'

import './StepInfo.scss'

const cnStepInfo = cn('StepInfo')

export interface IStepIList {
  title: string
  text: string
}
interface IStepInfo {
  currentStepInfo: number
  className?: string
  title?: string
  list: IStepIList[]
}

export const StepInfo: FC<IStepInfo> = ({
  currentStepInfo,
  className,
  title,
  list,
}) => {
  return (
    <div className={cnStepInfo(null, [className])}>
      {title && <div className={cnStepInfo('title')}>{title}</div>}

      <div className={cnStepInfo('list')}>
        {list.map((el, i) => (
          <div key={i} className={cnStepInfo('item')}>
            <div
              className={cnStepInfo('number', {
                active: i + 1 === currentStepInfo,
              })}
            >
              {i + 1}
            </div>

            <div className={cnStepInfo('info')}>
              <div
                className={cnStepInfo('name', {
                  active: i + 1 === currentStepInfo,
                })}
              >
                {el.title}
              </div>
              <div className={cnStepInfo('text')}> {el.text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
