import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { Memo } from '@/hoc/Memo'

import './styles/Items.scss'

const cnItems = cn('Items')

interface IItems
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  direction?: 'row' | 'column'
  children: ReactNode
  bottom?: boolean
}

export const Items: FC<IItems> = Memo(
  ({ className, direction, children, bottom }: IItems) => {
    return (
      <div
        className={cnItems(
          {
            row: direction === 'row',
            column: direction === 'column',
            bottom,
          },
          [className],
        )}
      >
        {children}
      </div>
    )
  },
)
