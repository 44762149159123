export type UserType = 'person' | 'company'

export class RegisterCompanyModel {
  org_name?: string = ''
  org_site?: string = ''

  country?: string
  address?: string
  address2?: string
}
export class RegisterModel extends RegisterCompanyModel {
  type: UserType = 'person'
  phone?: string = ''
  email?: string = ''
  code_email?: string = ''
  code_phone?: string = ''

  confirm_code?: string = ''
  // code_2fa?: string = ''

  conditions?: boolean = false
  confirm?: boolean = false
  agree?: boolean = false
}

export class RegisterResponseModel {
  is_verify?: number = 0
  token?: string = ''
}

export class RegisterRequestCodeModel extends RegisterCompanyModel {
  phone?: string = ''
  email?: string = ''
  input?: 'email' | 'phone' = 'email'
  type: UserType = 'person'
}

export class RegisterResponceCodeModel {
  'action' = ''
  'send_code' = 0
  'code'? = ''
}
