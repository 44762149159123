import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { Logo } from '@/components/Logo'
import { Title } from '@/components/Title'

import './AuthTop.scss'

const cnAuthTop = cn('AuthTop')

interface IAuthTop
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  to?: string
  title?: string
  line?: boolean
}

export const AuthTop: FC<IAuthTop> = ({ className, to = '/', title, line }) => {
  return (
    <div className={cnAuthTop({ line }, [className])}>
      <Logo to={to} className={cnAuthTop('logo')} />

      {title && (
        <Title small className={cnAuthTop('title')}>
          {title}
        </Title>
      )}
    </div>
  )
}
