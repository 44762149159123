import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const theme = (state: RootState) => state.appReducer
export const themeSelector = createSelector(
  theme,
  appReducer => appReducer.theme,
)

const paymentProgress = (state: RootState) => state.appReducer
export const paymentProgressSelector = createSelector(
  paymentProgress,
  appReducer => appReducer.paymentProgress,
)

const geo = (state: RootState) => state.appReducer
export const geoSelector = createSelector(geo, appReducer => appReducer.geo)

const currency = (state: RootState) => state.appReducer
export const currencyAppSelector = createSelector(
  currency,
  appReducer => appReducer.currency,
)

const direction = (state: RootState) => state.appReducer
export const directionSelector = createSelector(
  direction,
  appReducer => appReducer.direction,
)

const fiat = (state: RootState) => state.appReducer
export const fiatSelector = createSelector(fiat, appReducer => appReducer.fiat)

const fields = (state: RootState) => state.appReducer
export const fieldsSelector = createSelector(
  fields,
  appReducer => appReducer.fields,
)

const verifyInfo = (state: RootState) => state.appReducer
export const verifyInfoSelector = createSelector(
  verifyInfo,
  appReducer => appReducer.verifyInfo,
)
