import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import { IResponseDefaultData } from '@/core/types'

import { http } from '@/utils/http'

import { INotification } from './models/NotificationsModel'

class NotificationsService {
  async getNotifications(
    page: number,
  ): Promise<IResponseDefaultData<INotification[]>> {
    const res = await http.get<
      null,
      AxiosResponse<{ notifications: IResponseDefaultData<INotification[]> }>
    >(`/profile/notifications?page=${page}&perpage=5`)

    return res.data.notifications
  }

  async getLastNotifications(): Promise<INotification[]> {
    const res = await http.get<
      null,
      AxiosResponse<{ notifications: INotification[] }>
    >(`/profile/last_notifications`)
    return res.data.notifications
  }

  async setViewNotifications(ids: number[]): Promise<void> {
    const params = ids.length
      ? '?' + queryString.stringify({ id: ids }, { arrayFormat: 'bracket' })
      : ''

    await http.get(`/profile/view_notifications${params}`)
  }
}

export default new NotificationsService()
