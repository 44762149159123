import { cn } from '@bem-react/classname'
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode, // useEffect,
  // useState,
} from 'react'

import './AppProfile.scss'

const cnAppProfile = cn('AppProfile')

interface IAppProfile
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
}

export const AppProfile: FC<IAppProfile> = ({
  children,
  className,
}: IAppProfile) => {
  // const [data, setData] = useState<ReactNode[]>([])

  // useEffect(() => {
  //   if (Array.isArray(children)) {
  //     const content = children.filter(el => el.type.name === 'AppContent')
  //     const sidebar = children.filter(el => el.type.name === 'AppSidebar')

  //     setData([...content, ...sidebar])
  //   }
  // }, [children])

  return (
    <div className={cnAppProfile(null, [className])}>
      {/* {data.map(el => el)} */}
      {children}
    </div>
  )
}
