import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import { http } from '@/utils/http'

import {
  IDayCourseList,
  IQueryDirection,
  IResponseDayCourse,
  IResponseDirection,
} from './WalletsModel'

class WalletsService {
  async getWallets(): Promise<unknown[]> {
    const res = await http.get(`/profile/wallets2`)

    return res.data
  }

  async getDayCourse(): Promise<IDayCourseList[]> {
    const res = await http.get<
      IDayCourseList,
      AxiosResponse<IResponseDayCourse>
    >(`/day_course`)

    const objectArray = Object.entries(res.data.stats)

    const courses: IDayCourseList[] = objectArray.map(([key, value]) => {
      return {
        label: key,
        values: Object.values(value),
      }
    })

    return courses
  }

  async getDirections(query: IQueryDirection): Promise<IResponseDirection[]> {
    const str = queryString.stringify(query)

    const res = await http.get<
      IQueryDirection,
      AxiosResponse<{ directions: IResponseDirection[] }>
    >(`/directions?${str}`)

    return res.data.directions
  }
}

export default new WalletsService()
