import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { loadPaymentIframeThunk } from '@/core/services/Exchange/store/ExchangeSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'

import { AppContent, AppProfile, AppSidebar } from '@/components/AppProfile'
import { Back } from '@/components/Back'
import { Exchange } from '@/components/Exchange'
import { StepInfo } from '@/components/StepInfo'
import { Title } from '@/components/Title'

import { transactionSelector } from '../Transactions/store/TransactionsSelector'
import { getTransactionThunk } from '../Transactions/store/TransactionsSlice'
import './Payment.scss'

const cnPayment = cn('Payment')

export const Payment: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const { transactionId } = useParams<{ transactionId: string }>()
  const dispatch = useAppDispatch()
  const [setBackendError] = useBackendError()
  const transaction = useAppSelector(transactionSelector)

  const [url, setUrl] = useState<string>('')

  const getData = async (id: number) => {
    try {
      dispatch(getTransactionThunk(id)).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const loadPaymentIframe = async (id: number) => {
    try {
      const res = await dispatch(
        loadPaymentIframeThunk({ id, theme: 'light' }),
      ).unwrap()

      console.log(res)

      if (res && res.pay_url) {
        setUrl(res.pay_url)
      }
    } catch (errors) {
      setBackendError(errors)
    }
  }

  useEffect(() => {
    if (transactionId) {
      getData(+transactionId)
      loadPaymentIframe(+transactionId)
    }
  }, [transactionId])

  const listInfo = [
    {
      title: t('New Order'),
      text: t(
        'You have created a new order to buy cryptocurrency. Please read the information provided carefully. By clicking the "Pay" button, you confirm the correctness of the data in the purchase order. ',
      ),
    },
    {
      title: t('Payment'),
      text: t(
        'Enter your card details to pay for the cryptocurrency purchase order. All data is protected by the payment provider. After a successful payment, the cryptocurrency will be sent to your internal address in the Exflow wallet. ',
      ),
    },
    {
      title: t('Cards details'),
      text: t(
        'In the details of the transaction, you can monitor the status of your application. As soon as the application takes the status "Completed", the cryptocurrency will be sent to your address in the Exflow wallet. Return to the main page to see the balance of the wallet. ',
      ),
    },
  ]

  return (
    <>
      <Helmet>
        <title>
          {t('New order') + ' ' + (transaction ? transaction.id : '')}
        </title>
        <meta name='description' content={''} />
      </Helmet>
      <AppProfile>
        <AppContent>
          <div className={cnPayment('top')}>
            <Title>
              {t('New order')} #{transaction ? transaction.id : ''}
            </Title>
            <Back text={t('Back')} />
          </div>

          {transaction && (
            <div className={cnPayment('wrap')}>
              <div className={cnPayment('block')}>
                <div className={cnPayment('title')}>{t('You get')}</div>
                <div className={cnPayment('value')}>{transaction.give_sum}</div>
              </div>
              <div className={cnPayment('block')}>
                <div className={cnPayment('title')}>{t('You pay')}</div>
                <div className={cnPayment('value')}>{transaction.get_sum}</div>
              </div>
            </div>
          )}

          {url && (
            <div className={cnPayment('iframe')}>
              <iframe
                src={url}
                // @load="iframeLoaded"
              ></iframe>
            </div>
          )}

          {/* <div className={cnPayment('text')}>
            {t('Commissions are included ')}
          </div> */}

          <div className={cnPayment('text')}>
            {t(
              'Payment security is ensured by using the SSL protocol to transfer confidential information from the client to the server for further processing. The subsequent processing of the clients confidential data received (card details, registration data, etc.) is carried out in the processing center. This way, no one can get your credit card bank details.',
            )}
          </div>
        </AppContent>
        <AppSidebar padding>
          {transaction && transaction.awaiting_payment ? (
            <StepInfo
              list={listInfo}
              currentStepInfo={2}
              title={t('Transaction Steps')}
            />
          ) : (
            <Exchange />
          )}
        </AppSidebar>
      </AppProfile>
    </>
  )
})
