import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import './AppProfile.scss'

const cnAppProfile = cn('AppProfile')

interface IAppContent
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
}

export const AppContent: FC<IAppContent> = ({
  children,
  className,
}: IAppContent) => {
  return <div className={cnAppProfile('content', [className])}>{children}</div>
}
