import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Avatar.scss'

const cnAvatar = cn('Avatar')

interface IAvatar
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  alt?: string
  src?: string
  small?: boolean
}

export const Avatar: FC<IAvatar> = Memo(
  ({ alt, src, className, small }: IAvatar) => {
    return (
      <div className={cnAvatar({ empty: !src, small }, [className])}>
        {src ? (
          <img src={src} alt={alt} />
        ) : (
          <Icon name='user' width={small ? 20 : 30} height={small ? 24 : 34} />
        )}
      </div>
    )
  },
)
