import { cn } from '@bem-react/classname'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  FocusEvent,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import { isIOS } from 'react-device-detect'
import { Country } from 'react-phone-number-input'
import { Transition } from 'react-transition-group'
import { v4 as uuidv4 } from 'uuid'

import { TypeMargin, TypeSize } from '@/core/types'

import { Memo } from '@/hoc/Memo'

import './Field.scss'

const cnField = cn('Field')

export type FieldType = 'text' | 'number' | 'password' | 'email' | 'url' | 'tel'

export interface FieldSchema
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  uid?: string
  type?: FieldType
  label?: string
  name: string
  errors?: string
  placeholder?: string
  classNameInput?: string
  classNameLabel?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  iconLeft?: ReactNode | string
  iconRight?: ReactNode | string
  noArrow?: boolean
  returnValue?: boolean
  isBackgroundSquare?: boolean
  backgroundSquare?: string
  handleOnBlur?: (e?: FocusEvent<HTMLInputElement>) => void
  handleCustomChange?: (
    e?: ChangeEvent<HTMLInputElement> | number | string,
  ) => void
  pattern?: string
  isNumber?: boolean
  sizeInput?: TypeSize
  mb?: TypeMargin
  info?: string
  countryCode?: Country | null
}

export const Field: FC<FieldSchema> = Memo(
  forwardRef(
    (
      {
        uid = 'input-' + uuidv4(),
        type = 'text',
        value,
        label,
        onChange,
        onBlur,
        name,
        required,
        errors,
        placeholder,
        readonly = false,
        className,
        classNameLabel,
        classNameInput,
        disabled,
        iconLeft,
        iconRight,
        noArrow,
        handleOnBlur,
        returnValue,
        handleCustomChange,
        children,
        pattern,
        isNumber,
        sizeInput = 'xxl',
        mb = 'sm',
        info,
        onFocus,
        ...props
      }: FieldSchema,
      ref: ForwardedRef<HTMLInputElement>,
    ) => {
      const [patternLocal, setPatternLocal] = useState<string | undefined>(
        undefined,
      )

      useEffect(() => {
        const p = '^-?[0-9]\\d*\\.?\\d*$'
        if (isNumber) setPatternLocal(p)
        if (pattern) setPatternLocal(pattern)
      }, [isNumber, pattern])

      const hanleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (onChange) {
          onChange(e)
        } else if (handleCustomChange) {
          handleCustomChange(returnValue ? e.target.value : e)
        }
      }

      const hanleBlur = (e: FocusEvent<HTMLInputElement>): void => {
        if (type === 'password' && onBlur) onBlur(e)
      }

      const hanleFocus = (e: FocusEvent<HTMLInputElement>): void => {
        onFocus && onFocus(e)
      }

      return (
        <div
          className={cnField(
            {
              xxl: mb === 'xxl',
              xl: mb === 'xl',
              lg: mb === 'lg',
              md: mb === 'md',
              sm: mb === 'sm',
              none: mb === 'none',
              tel: type === 'tel',
            },
            [className],
          )}
        >
          {label && (
            <label
              htmlFor={uid}
              className={cnField(
                'label',
                {
                  required,
                },
                [classNameLabel],
              )}
            >
              {label}
            </label>
          )}

          <div className={cnField('wrap')}>
            {iconLeft && (
              <div className={cnField('icon', { left: true })}>{iconLeft}</div>
            )}
            <input
              placeholder={placeholder}
              id={uid}
              value={value}
              name={name}
              type={type}
              onChange={hanleChange}
              onBlur={e => {
                hanleBlur(e)
                handleOnBlur && handleOnBlur(e)
              }}
              onFocus={hanleFocus}
              disabled={disabled}
              readOnly={readonly}
              className={cnField(
                'input',
                {
                  errors: !!errors,
                  left: !!iconLeft,
                  right: !!iconRight || !!errors || !!info,
                  arrow: noArrow,
                  xxl: sizeInput === 'xxl',
                  xl: sizeInput === 'xl',
                  lg: sizeInput === 'lg',
                  md: sizeInput === 'md',
                  sm: sizeInput === 'sm',
                  ios: isIOS,
                  tel: type === 'tel',
                },
                [classNameInput],
              )}
              ref={ref}
              pattern={patternLocal}
              {...props}
            />

            {iconRight && !errors && !info && (
              <div
                className={cnField('icon', {
                  right: true,
                })}
              >
                {iconRight}
              </div>
            )}
          </div>

          {children && children}

          <Transition in={!!errors} timeout={500} mountOnEnter unmountOnExit>
            {(state: string) => (
              <div className={cnField('error', [state])}>
                {/* <Icon name='alert-error' width={15} height={15} /> */}
                {errors}
              </div>
            )}
          </Transition>
        </div>
      )
    },
  ),
)
