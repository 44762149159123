import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { PrivateRoute } from '@/core/PrivateRoute'

import { Memo } from '@/hoc/Memo'

import { getItem, setItem } from '@/utils/persistanceStorage'

import { Toast } from '@/components/Toast'

import { Login, Register } from './Auth'
import { ExchangePage } from './ExchangePage'
import { Home } from './Home'
import { Notifications } from './Notifications'
import { Order } from './Order'
import { PageNotFound } from './PageNotFound'
import { Payment } from './Payment'
import { Settings } from './Settings'
import { Transaction, Transactions } from './Transactions'
import { UserVerification } from './Verification'

const langs = ['en', 'ru', 'it']

export const AppCunex = Memo(() => {
  const { i18n } = useTranslation()
  useEffect(() => {
    const lang = getItem('lang')

    if (lang && langs.includes(lang)) {
      i18n.changeLanguage(lang)
    } else {
      i18n.changeLanguage('en')
      setItem('lang', 'en')
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path='registration' element={<Register />} />
          <Route path='profile' element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path='transactions' element={<Transactions />} />
            <Route path='transactions/:id' element={<Transaction />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='payment/:transactionId' element={<Payment />} />
            <Route path='settings' element={<Settings />} />
            <Route path='order' element={<Order />} />
            <Route path='verification' element={<UserVerification />} />
            <Route path='exchange' element={<ExchangePage />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <Toast />
    </>
  )
})
