import axios, { AxiosHeaders, AxiosResponse } from 'axios'

import {
  ICompanyResponseVerify,
  IFieldValueVerify,
  IFieldVerify,
  IUserRequestVerify,
  IUserResponseVerify,
  IVerifyData,
  IVerifyInfo,
} from '@/core/interfaces'

import { http } from '@/utils/http'

import { IDataNewLocation } from './AppModel'

class AppService {
  async getGeolocation(): Promise<IDataNewLocation> {
    // const url = process.env.REACT_APP_IPBASE
    // const res = await axios.get(`${url}`)

    const { data } = await axios.get('https://api.ipify.org?format=json')
    const res = await axios.get<null, AxiosResponse<IDataNewLocation>>(
      `https://ipinfo.io/${data.ip}?token=db5990705ff21d`,
    )

    return res.data
  }

  async getTokenVerify(data: IUserRequestVerify): Promise<IUserResponseVerify> {
    const res = await http.post<
      IUserRequestVerify,
      AxiosResponse<IUserResponseVerify>
    >(`/verify/create`, data)

    return res.data
  }

  async getVerifyManual(): Promise<IVerifyData> {
    const res = await http.get<
      null,
      AxiosResponse<{
        fields: ICompanyResponseVerify
        is_verify?: IVerifyInfo
      }>
    >(`/verify/manual`)

    const arr: IFieldVerify[] = []

    for (const [key, value] of Object.entries(res.data.fields)) {
      arr.push({
        name: key,
        ...value,
      })
    }

    return {
      fields: arr,
      is_verify: res.data.is_verify,
    }
  }

  async uploadVerifyManual(data: FormData): Promise<void> {
    const headers = new AxiosHeaders()
    headers.setContentType('multipart/form-data')
    await http.post(`/verify/manual/upload`, data, {
      headers,
    })
  }

  async deleteFileVerifyManual(name: string): Promise<void> {
    await http.delete(`/verify/manual/${name}/delete`)
  }

  async sendVerifyManual(): Promise<void> {
    await http.post(`/verify/manual/send`)
  }

  async sendValueVerifyManual(data: IFieldValueVerify): Promise<void> {
    await http.post(`/verify/manual/value`, data)
  }
}

export default new AppService()
