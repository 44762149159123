import { FC } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import i18n from '@/core/i18n'

import { Memo } from '@/hoc/Memo'

import { store } from './core/store'
import { AppCunex } from './modules'

export const App: FC = Memo(() => {
  return (
    <>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <AppCunex />
        </I18nextProvider>
      </Provider>
    </>
  )
})
