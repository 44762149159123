import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'
import useWindowSize from '@/hooks/useWindowSize'

import dayjs from '@/utils/dayjs'

import { AppContent, AppProfile, AppSidebar } from '@/components/AppProfile'
import { Badge } from '@/components/Badge'
import { Exchange } from '@/components/Exchange'
import { Cell, Footer, Grid, Head, Row } from '@/components/Grid'
import { Item, Items } from '@/components/Items'
import { Pagination } from '@/components/Pagination'
import { Title } from '@/components/Title'

import './Notifications.scss'
import { INotification } from './models/NotificationsModel'
import {
  lastNotificationsSelector,
  notificationsSelector,
} from './store/notifications.selector'
import {
  getNotificationsThunk,
  setViewNotificationsThunk,
} from './store/notifications.slice'

const cnNotifications = cn('Notifications')

export const Notifications: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()

  const [setBackendError] = useBackendError()
  const { lessDesktop, lessTablet } = useWindowSize()
  const notifications = useAppSelector(notificationsSelector)
  const last = useAppSelector(lastNotificationsSelector)

  const [data, setData] = useState<INotification[]>([])
  const [page, setPage] = useState<number>(1)

  const getData = async (page: number) => {
    try {
      await dispatch(getNotificationsThunk(page)).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const setViewNotifications = async (ids: number[]) => {
    try {
      await dispatch(setViewNotificationsThunk(ids)).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  useEffect(() => {
    if (last.length) {
      setViewNotifications(last.map(el => el.id))
    }
  }, [last])

  useEffect(() => {
    getData(page)
  }, [page])

  useEffect(() => {
    if (notifications) {
      setData(notifications.data)
    }
  }, [notifications])

  const onPageChange = (page: number) => {
    setPage(page)
  }

  return (
    <>
      <AppProfile>
        <AppContent>
          <Title>{t('Notifications')}</Title>

          <div className={cnNotifications('table')}>
            {!lessTablet ? (
              <Grid>
                <Row>
                  <Head>{t('ID')}</Head>
                  <Head>{t('Status')}</Head>
                  <Head>{t('Date')}</Head>
                </Row>

                {data.map(notification => (
                  <Row key={notification.id}>
                    <Cell>{notification.id}</Cell>
                    <Cell>
                      <Badge
                        icon={notification.type.class}
                        title={t(notification.type.title)}
                      />
                    </Cell>
                    <Cell>
                      {dayjs(notification.created_at, 'DD.MM.YYYY').format(
                        'MM.DD.YYYY',
                      )}
                      <span className={cnNotifications('slash')}>|</span>
                      {dayjs(notification.created_at, 'DD.MM.YYYY').format(
                        'hh.mm.ss',
                      )}
                    </Cell>
                  </Row>
                ))}
              </Grid>
            ) : (
              <>
                {data.map((notification, i) => (
                  <Items bottom={i + 1 !== data.length}>
                    <Item small label={t('ID')}>
                      {notification.id}
                    </Item>
                    <Item small label={t('Status')}>
                      <Badge
                        small
                        icon={notification.type.class}
                        title={t(notification.type.title)}
                      />
                    </Item>
                    <Item small label={t('Date')} mobile={lessTablet}>
                      {dayjs(notification.created_at, 'DD.MM.YYYY').format(
                        'MM.DD.YYYY',
                      )}
                      <span className={cnNotifications('slash')}>|</span>
                      {dayjs(notification.created_at, 'DD.MM.YYYY').format(
                        'hh.mm.ss',
                      )}
                    </Item>
                  </Items>
                ))}
              </>
            )}

            {notifications && notifications.total > notifications.per_page && (
              <Footer>
                <Pagination
                  total={notifications.total}
                  currentPage={notifications.current_page}
                  pageSize={notifications.per_page}
                  onPageChange={onPageChange}
                />
              </Footer>
            )}
          </div>
        </AppContent>

        {!lessDesktop && (
          <AppSidebar>
            <Exchange />
          </AppSidebar>
        )}
      </AppProfile>
    </>
  )
})
