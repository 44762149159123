/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  IFieldValueVerify,
  IFieldVerify,
  IUserRequestVerify,
  IVerifyInfo,
} from '@/core/interfaces'
import { ThemeType } from '@/core/types'

import { IQueryDirections } from '../../Wallets/WalletsModel'
import { IDataNewLocation } from '../AppModel'
import AppService from '../AppService'

interface AppState {
  theme: ThemeType
  paymentProgress: any | null
  geo: IDataNewLocation | null
  currency: string
  direction: IQueryDirections | null
  fiat: string
  fields: IFieldVerify[]
  verifyInfo: IVerifyInfo | null
}

const initialState: AppState = {
  theme: 'light',
  paymentProgress: null,
  geo: null,
  currency: 'EUR',
  direction: null,
  fiat: '',
  fields: [],
  verifyInfo: null,
}

export const getGeolocationThunk = createAsyncThunk(
  'app/getGeolocationThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await AppService.getGeolocation()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getTokenVerifyThunk = createAsyncThunk(
  'app/getTokenVerifyThunk',
  async (data: IUserRequestVerify, { rejectWithValue }) => {
    try {
      return await AppService.getTokenVerify(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getVerifyManualThunk = createAsyncThunk(
  'app/getVerifyManualThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await AppService.getVerifyManual()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const uploadVerifyManualThunk = createAsyncThunk(
  'app/uploadVerifyManualThunk',
  async (data: FormData, { rejectWithValue }) => {
    try {
      return await AppService.uploadVerifyManual(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const deleteFileVerifyManualThunk = createAsyncThunk(
  'app/deleteFileVerifyManualThunk',
  async (name: string, { rejectWithValue }) => {
    try {
      return await AppService.deleteFileVerifyManual(name)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const sendVerifyManualThunk = createAsyncThunk(
  'app/sendVerifyManualThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await AppService.sendVerifyManual()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const sendValueVerifyManualThunk = createAsyncThunk(
  'app/sendValueVerifyManualThunk',
  async (data: IFieldValueVerify, { rejectWithValue }) => {
    try {
      return await AppService.sendValueVerifyManual(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload
    },

    setPaymentProgressData(state, action: PayloadAction<any>) {
      state.paymentProgress = action.payload
    },

    changeAppCurrency(state, action: PayloadAction<string>) {
      state.currency = action.payload
    },

    changeDirectionsExchange(state, action: PayloadAction<IQueryDirections>) {
      state.direction = action.payload
    },

    changeSymbolFiat(state, action: PayloadAction<string>) {
      state.fiat = action.payload
    },
  },

  extraReducers: builder => {
    builder

      .addCase(getGeolocationThunk.fulfilled, (state, action) => {
        state.geo = action.payload
      })
      .addCase(getGeolocationThunk.rejected, state => {
        state.geo = null
      })

      .addCase(getVerifyManualThunk.fulfilled, (state, action) => {
        state.fields = action.payload.fields
        state.verifyInfo = action.payload.is_verify || null
      })
      .addCase(getVerifyManualThunk.rejected, state => {
        state.fields = []
        state.verifyInfo = null
      })
  },
})

export const { actions, reducer } = appSlice
export const {
  changeTheme,
  setPaymentProgressData,
  changeAppCurrency,
  changeDirectionsExchange,
  changeSymbolFiat,
} = actions
