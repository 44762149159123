import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'
import useVefifyToken from '@/hooks/useVerifyToken'
import useWindowSize from '@/hooks/useWindowSize'

import { removeEmpty } from '@/utils/removeEmpty'
import yup from '@/utils/yup-extended'

import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { Field } from '@/components/Field'
import { FieldCode } from '@/components/FieldCode'
import { FieldPhone } from '@/components/FieldPhone'

import { AuthTop } from './components/AuthTop'
import { AuthWrap } from './components/AuthWrap'
import { LoginModel } from './models/LoginModel'
import { getCodeThunk, loginThunk } from './store/AuthSlice'
import './styles/Login.scss'

const cnLogin = cn('Login')

export const Login: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { lessMobile } = useWindowSize()
  const [setBackendError] = useBackendError()
  const { verify } = useVefifyToken()

  const [view, setView] = useState<'phone' | 'email'>('email')
  const [step, setStep] = useState<'form' | 'verify'>('form')
  const [val, setVal] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSuccsess, setSuccsess] = useState<boolean>(false)
  const [is2fa, setIs2fa] = useState<boolean>(false)

  useEffect(() => {
    if (verify) {
      navigate('/profile')
    }
  }, [verify])

  useEffect(() => {
    reset()
  }, [view])

  const SchemaEmail = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t('Enter the correct email'))
      .required(t('This field is required')),
  })

  const SchemaPhone = yup.object().shape({
    phone: yup
      .string()
      .trim()
      .isValidPhone(t('Enter the correct phone number'))
      .required(t('This field is required')),
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    reset,
    getValues,
    setError,
  } = useForm<LoginModel>({
    defaultValues: new LoginModel(),
    resolver: yupResolver(view === 'email' ? SchemaEmail : SchemaPhone),
    mode: 'onChange',
  })

  const getCode = async () => {
    const values = getValues()
    const data = removeEmpty(values)

    try {
      await dispatch(
        getCodeThunk({
          ...data,
          type: view,
        }),
      ).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const login = async (form: LoginModel) => {
    const data = removeEmpty(form)

    try {
      const res = await dispatch(loginThunk(data)).unwrap()

      setStep('verify')
      setVal(view === 'email' ? data.email : data.phone)

      if (res.is_verify) {
        await getCode()
      }

      if (res.is_2fa) {
        setIs2fa(true)
      }

      if (res.token) {
        setLoading(false)
        setSuccsess(true)

        setTimeout(() => {
          navigate('/profile')
        }, 1000)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (errors: any) {
      setBackendError(errors)

      setLoading(false)

      if (errors && errors.data) {
        if (errors.data.code) {
          setError('code', { message: t('Incorrect code') })
        }

        if (errors.data.email) {
          setError('email', { message: t('Invalid user login') })
        }

        if (errors.data.code_2fa) {
          setError('code_2fa', { message: t('The code 2fa field is required') })
        }
      }
    }
  }

  const handleOnChangeCode = async (e: string, name: string) => {
    const values = getValues()

    if (e.length === 6) {
      setLoading(true)

      const data: LoginModel = {
        ...values,
        confirm_code: '1',
        [name]: e,
      }

      reset(data)

      if (
        is2fa &&
        data.code &&
        data.code.length === 6 &&
        data.code_2fa &&
        data.code_2fa.length === 6
      ) {
        await login(data)
      } else if (!is2fa) {
        await login(data)
      }
    } else if (e.length < 6 && values.code?.length === 6) {
      reset(v => {
        return {
          ...v,
          [name]: '',
        }
      })
    }
  }

  const onClick = () => {
    navigate('/registration')
  }

  const resendCode = async () => {
    reset(v => {
      return {
        ...v,
        code: '',
      }
    })

    await getCode()
  }

  return (
    <>
      <Helmet>
        <title>{t('Login')}</title>
      </Helmet>
      <AuthWrap>
        <Card className={cnLogin('card')} resetStyle={lessMobile}>
          <AuthTop title={t('Authorization')} />
          <form className={cnLogin('form')} onSubmit={handleSubmit(login)}>
            {step === 'form' ? (
              <>
                <div className={cnLogin('center')}>
                  <div className={cnLogin('switch')}>
                    <button
                      type='button'
                      className={cnLogin('btn', { active: view === 'email' })}
                      onClick={() => setView('email')}
                    >
                      {t('Email')}
                    </button>
                    <button
                      type='button'
                      className={cnLogin('btn', { active: view === 'phone' })}
                      onClick={() => setView('phone')}
                    >
                      {t('Phone')}
                    </button>
                  </div>

                  {view === 'email' ? (
                    <Field
                      {...register('email')}
                      // label={t('Email address')}
                      placeholder={t('Insert your email')}
                      errors={errors && errors.email && errors.email.message}
                      mb='none'
                    />
                  ) : (
                    <Controller
                      name='phone'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FieldPhone
                          placeholder={t('Insert your number')}
                          errors={
                            errors && errors.phone && errors.phone.message
                          }
                          mb='none'
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  )}
                </div>
                <div className={cnLogin('bottom')}>
                  <Button
                    fluid
                    size='lg'
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    {t('Log in')}
                  </Button>

                  <Button fluid size='lg' color='gray' onClick={onClick}>
                    {t('Register')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={cnLogin('verify')}>
                  <div className={cnLogin('text')}>
                    {t('We have sent a confirmation code to', { value: val })}
                  </div>
                  <div className={cnLogin('text')}>
                    {t('Insert code to access your account')}
                  </div>

                  <FieldCode
                    handleOnChange={e => handleOnChangeCode(e, 'code')}
                    placeholder='＊'
                    success={isSuccsess}
                    loading={isLoading}
                    resendCode={resendCode}
                    error={errors && !!errors.code}
                    isTime
                  />
                </div>

                {is2fa && (
                  <div className={cnLogin('verify', { bottom: true })}>
                    <div className={cnLogin('text')}>
                      {t('Enter the code from the Google Authenticator app')}
                    </div>

                    <FieldCode
                      handleOnChange={e => handleOnChangeCode(e, 'code_2fa')}
                      placeholder='＊'
                      success={isSuccsess}
                      isTime={false}
                      error={errors && !!errors.code_2fa}
                    />
                  </div>
                )}
              </>
            )}
          </form>
        </Card>
      </AuthWrap>
    </>
  )
})
