import { cn } from '@bem-react/classname'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Memo } from '@/hoc/Memo'

import './Switcher.scss'

const cnSwitcher = cn('Switcher')

export type SwitcherType = 'checkbox' | 'radio'
export type SwitcherColorType = 'dark' | 'blue'

export interface SwitcherSchema
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  uid?: string
  type?: SwitcherType
  isChecked?: boolean
  disabled?: boolean
  handleCustomChange?: (e: boolean | number | string) => void
  classNameLabel?: string
  children?: ReactNode
  errors?: string
}

export const Switcher: FC<SwitcherSchema> = Memo(
  forwardRef(
    (
      {
        type = 'checkbox',
        uid = `${type}-` + uuidv4(),
        onChange,
        handleCustomChange,
        name,
        disabled,
        className,
        isChecked,
        classNameLabel,
        children,
        errors,
        ...props
      }: SwitcherSchema,
      ref: ForwardedRef<HTMLInputElement>,
    ) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (isChecked !== undefined && handleCustomChange) {
          handleCustomChange(e.target.checked)
        } else if (handleCustomChange) {
          handleCustomChange(e.target.value)
        } else if (onChange) {
          onChange(e)
        }
      }

      return (
        <div className={cnSwitcher(null, [className])}>
          <input
            name={name}
            type={type}
            id={uid}
            className={cnSwitcher('input')}
            onChange={handleChange}
            checked={isChecked}
            disabled={disabled}
            ref={ref}
            {...props}
          />
          <label
            htmlFor={uid}
            className={cnSwitcher(
              'label',
              {
                disabled,
                error: !!errors,
              },
              [classNameLabel],
            )}
          >
            <span className={cnSwitcher('text', { error: !!errors })}>
              {children}
            </span>
          </label>
        </div>
      )
    },
  ),
)
