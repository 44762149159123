import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import {
  directionSelector,
  fiatSelector,
} from '@/core/services/App/store/AppSelector'
import {
  changeDirectionsExchange,
  changeSymbolFiat,
} from '@/core/services/App/store/AppSlice'
import { IQueryDirection } from '@/core/services/Wallets/WalletsModel'
import { directionsSelector } from '@/core/services/Wallets/store/WalletsSelector'
import { getDirectionsThunk } from '@/core/services/Wallets/store/WalletsSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'
import useWindowSize from '@/hooks/useWindowSize'

import { AppContent, AppProfile, AppSidebar } from '@/components/AppProfile'
import { Exchange } from '@/components/Exchange'
import { Loading } from '@/components/Loading'
import { Title } from '@/components/Title'

import './Home.scss'
import { Currency } from './components/Currency'

const cnHome = cn('Home')

export const Home: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { lessDesktop } = useWindowSize()
  const [setBackendError] = useBackendError()
  const directions = useAppSelector(directionsSelector)
  const direction = useAppSelector(directionSelector)
  const fiat = useAppSelector(fiatSelector)

  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (direction) {
      const d = direction.direction.split('/')

      const data = {
        type: direction.type,
        give: direction.type === 'Buy' ? d[0] : d[1],
        get: direction.type === 'Buy' ? d[1] : d[0],
      }

      if (directions.length === 0) {
        setLoading(true)
        getDirections(data)
      }
    }
  }, [direction])

  useEffect(() => {
    if (!direction && lessDesktop) {
      dispatch(
        changeDirectionsExchange({
          type: 'Buy',
          direction: 'EUR/BTC',
        }),
      )

      dispatch(changeSymbolFiat('€'))
    }
  }, [direction, lessDesktop])

  const getDirections = async (query: IQueryDirection) => {
    try {
      await dispatch(getDirectionsThunk(query)).unwrap()

      setLoading(false)
    } catch (errors) {
      setLoading(false)
      setBackendError(errors)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('home-title')}</title>
        <meta name='description' content={t('home-description')} />
      </Helmet>

      <AppProfile>
        <AppContent>
          <Title>{t('home-h1')}</Title>

          {isLoading ? (
            <Loading position='center' className={cnHome('loading')} />
          ) : (
            <div className={cnHome('list')}>
              {directions.length > 0 ? (
                <>
                  {directions.map(direction => (
                    <Currency
                      key={direction.id}
                      element={direction}
                      currency={fiat}
                    />
                  ))}
                </>
              ) : (
                <div>No data</div>
              )}
            </div>
          )}
        </AppContent>

        {!lessDesktop && (
          <AppSidebar>
            <Exchange />
          </AppSidebar>
        )}
      </AppProfile>
    </>
  )
})
