import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import './Grid.scss'

const cnGrid = cn('Grid')

interface IGrid
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
}

export const Row: FC<IGrid> = ({ children, className, onClick }) => {
  return (
    <div
      className={cnGrid('row', { pointer: !!onClick }, [className])}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
