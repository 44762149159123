/* eslint-disable @typescript-eslint/no-explicit-any */
import Yup from './yup-extended'

export const createValidationSchema = (fields: any = []) => {
  const ObjectSchema = fields.reduce((schema: any, field: any) => {
    if (field?.validations?.length) {
      schema[field.name] = field.validations.reduce(
        (yup: any, type: any) => {
          if (field.params[type]) {
            const params = Array.isArray(field.params[type])
              ? field.params[type]
              : [field.params[type]]

            yup = yup[type](...params)
          } else {
            yup = yup[type]()
          }

          return yup
        },
        { ...Yup },
      )
    }

    return schema
  }, {})

  return Yup.object().shape({ ...ObjectSchema })
}
