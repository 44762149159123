import { AxiosHeaders, AxiosResponse } from 'axios'

import { http } from '@/utils/http'
import { setItem } from '@/utils/persistanceStorage'

import {
  LoginModel,
  LoginRequestCodeModel,
  LoginResponseModel,
} from '../models/LoginModel'
import { IRequestTwoFA, IResponseTwoFA, UserModel } from '../models/UserModel'

class LoginService {
  async login(data: LoginModel): Promise<LoginResponseModel> {
    const res = await http.post<LoginModel, AxiosResponse<LoginResponseModel>>(
      `/auth/login`,
      data,
    )

    if (res.data && res.data.token) {
      setItem('token', res.data.token, false)
    }

    return res.data
  }

  async getCode(data: LoginRequestCodeModel): Promise<unknown> {
    const res = await http.post(`/auth/login/code/${data.type}`, data)

    return res.data
  }

  async getCurrentUser(): Promise<UserModel> {
    const res = await http.get(`/auth/user`)

    return res.data
  }

  async changeTwoFA(data?: IRequestTwoFA): Promise<IResponseTwoFA> {
    const res = await http.post<IRequestTwoFA, AxiosResponse<IResponseTwoFA>>(
      `/profile/2fa`,
      data,
    )

    return res.data
  }

  async uploadAvatar(file: FormData): Promise<void> {
    const headers = new AxiosHeaders()
    headers.setContentType('multipart/form-data')
    await http.post(`/profile/change_avatar`, file, {
      headers,
    })
  }

  async changePhone(phone: string): Promise<void> {
    await http.post(`/profile/change_phone`, { phone })
  }

  async changeEmail(email: string): Promise<void> {
    await http.post(`/profile/change_email`, { email })
  }

  async changeUserType(): Promise<void> {
    const res = await http.get(`auth/change_account`)

    if (res.data && res.data.token) {
      setItem('token', res.data.token, false)
    }
  }
}

export default new LoginService()
