import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import './Grid.scss'

const cnGrid = cn('Grid')

interface IGrid
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
}

export const Head: FC<IGrid> = ({ children, className }) => {
  return <div className={cnGrid('head', [className])}>{children}</div>
}
