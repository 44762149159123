import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Badge.scss'

const cnBadge = cn('Badge')

interface IBadge
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  icon?: string
  small?: boolean
}

export const Badge: FC<IBadge> = Memo(
  ({ className, title, icon, small }: IBadge) => {
    return (
      <div
        className={cnBadge(
          {
            small,
          },
          [className],
        )}
      >
        {icon && (
          <div className={cnBadge('icon', { small })}>
            <Icon
              name={`status-${icon}`}
              width={small ? 16 : 24}
              height={small ? 16 : 24}
            />
          </div>
        )}
        {title && <div className={cnBadge('title')}>{title}</div>}
      </div>
    )
  },
)
