import { AxiosResponse } from 'axios'

import { IResponseDefaultData } from '@/core/types'

import { http } from '@/utils/http'

import { ITransaction } from './models/TransactionsModel'

class TransactionsService {
  async getTransactions(
    page: number,
  ): Promise<IResponseDefaultData<ITransaction[]>> {
    const res = await http.get(`/profile/transactions?page=${page}&perpage=5`)
    return res.data
  }

  async getTransaction(id: number): Promise<ITransaction> {
    const res = await http.get<
      ITransaction,
      AxiosResponse<{ transaction: ITransaction }>
    >(`/exchange/${id}`)

    if (
      res &&
      res.data &&
      res.data.transaction &&
      res.data.transaction.manual
    ) {
      const arr = Object.values(res.data.transaction.manual)

      return {
        ...res.data.transaction,
        manual: arr,
      }
    }

    return res.data.transaction
  }

  async cancelOrder(id: number): Promise<number> {
    const res = await http.get<null, AxiosResponse>(
      `/exchange/${id}?is_cancel=1`,
    )

    return res.status
  }
}

export default new TransactionsService()
