import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { LoginModel, LoginRequestCodeModel } from '../models/LoginModel'
import {
  RegisterModel,
  RegisterRequestCodeModel,
} from '../models/RegisterModel'
import { IRequestTwoFA, IResponseTwoFA, UserModel } from '../models/UserModel'
import LoginService from '../services/LoginService'
import RegisterService from '../services/RegisterService'

interface AuthState {
  user: UserModel | null
  twoFa: IResponseTwoFA | null
}

const initialState: AuthState = {
  user: null,
  twoFa: null,
}

export const loginThunk = createAsyncThunk(
  'auth/loginThunk',
  async (data: LoginModel, { rejectWithValue }) => {
    try {
      return await LoginService.login(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getCodeThunk = createAsyncThunk(
  'auth/getCodeThunk',
  async (data: LoginRequestCodeModel, { rejectWithValue }) => {
    try {
      return await LoginService.getCode(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getCurrentUserThunk = createAsyncThunk(
  'auth/getCurrentUserThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await LoginService.getCurrentUser()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const changeTwoFAThunk = createAsyncThunk(
  'auth/changeTwoFAThunk',
  async (data: IRequestTwoFA | undefined, { rejectWithValue }) => {
    try {
      return await LoginService.changeTwoFA(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const updateAvatarThunk = createAsyncThunk(
  'auth/updateAvatarThunk',
  async (file: FormData, { rejectWithValue }) => {
    try {
      return await LoginService.uploadAvatar(file)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const registrationThunk = createAsyncThunk(
  'auth/registrationThunk',
  async (data: RegisterModel, { rejectWithValue }) => {
    try {
      return await RegisterService.registration(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getRegisterCodeThunk = createAsyncThunk(
  'auth/getRegisterCodeThunk',
  async (data: RegisterRequestCodeModel, { rejectWithValue }) => {
    try {
      return await RegisterService.getCode(data)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const changeUserTypeThunk = createAsyncThunk(
  'auth/changeUserTypeThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await LoginService.changeUserType()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(getCurrentUserThunk.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(getCurrentUserThunk.rejected, state => {
        state.user = null
      })

      .addCase(changeTwoFAThunk.fulfilled, (state, action) => {
        state.twoFa = action.payload
      })
      .addCase(changeTwoFAThunk.rejected, state => {
        state.twoFa = null
      })
  },
})

export const { actions, reducer } = authSlice
