import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  IDayCourseList,
  IQueryDirection,
  IResponseDirection,
} from '../WalletsModel'
import WalletsService from '../WalletsService'

interface AppState {
  wallets: unknown[]
  courses: IDayCourseList[]
  directions: IResponseDirection[]
}

const initialState: AppState = {
  wallets: [],
  courses: [],
  directions: [],
}

export const getWalletsThunk = createAsyncThunk(
  'wallets/getWalletsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await WalletsService.getWallets()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getDayCourseThunk = createAsyncThunk(
  'wallets/getDayCourseThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await WalletsService.getDayCourse()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getDirectionsThunk = createAsyncThunk(
  'wallets/getDirectionsThunk',
  async (query: IQueryDirection, { rejectWithValue }) => {
    try {
      return await WalletsService.getDirections(query)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

const walletsSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(getWalletsThunk.fulfilled, (state, action) => {
        state.wallets = action.payload
      })
      .addCase(getWalletsThunk.rejected, state => {
        state.wallets = []
      })

      .addCase(getDayCourseThunk.fulfilled, (state, action) => {
        state.courses = action.payload
      })
      .addCase(getDayCourseThunk.rejected, state => {
        state.courses = []
      })

      .addCase(getDirectionsThunk.fulfilled, (state, action) => {
        state.directions = action.payload
      })
      .addCase(getDirectionsThunk.rejected, state => {
        state.directions = []
      })
  },
})

export const { actions, reducer } = walletsSlice
