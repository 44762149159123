import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'
import useWindowSize from '@/hooks/useWindowSize'

import dayjs from '@/utils/dayjs'

import { AppContent, AppProfile, AppSidebar } from '@/components/AppProfile'
import { Badge } from '@/components/Badge'
import { Empty } from '@/components/Empty'
import { Exchange } from '@/components/Exchange'
import { Cell, Footer, Grid, Head, Row } from '@/components/Grid'
import { Item, Items } from '@/components/Items'
import { Loading } from '@/components/Loading'
import { Pagination } from '@/components/Pagination'
import { Title } from '@/components/Title'

import { ITransaction } from './models/TransactionsModel'
import { transactionsSelector } from './store/TransactionsSelector'
import { getTransactionsThunk } from './store/TransactionsSlice'
import './styles/Transactions.scss'

const cnTransactions = cn('Transactions')

export const Transactions: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const [setBackendError] = useBackendError()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { lessDesktop, lessTablet, lessXMobile } = useWindowSize()
  const transactions = useAppSelector(transactionsSelector)

  const [data, setData] = useState<ITransaction[]>([])
  const [page, setPage] = useState<number>(1)
  const [isLoading, setLoading] = useState<boolean>(false)

  const getData = async (page: number) => {
    try {
      dispatch(getTransactionsThunk(page)).unwrap()
    } catch (errors) {
      setBackendError(errors)
      setLoading(false)
    }
  }

  useEffect(() => {
    getData(page)
    setLoading(true)
  }, [page])

  useEffect(() => {
    if (transactions) {
      setData(transactions.data)
      setLoading(false)
    }
  }, [transactions])

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const onClick = (id: number) => {
    navigate(`${pathname}/${id}`)
  }

  return (
    <>
      <Helmet>
        <title>{t('transactions-title')}</title>
        <meta name='description' content={t('transactions-description')} />
      </Helmet>

      <AppProfile>
        <AppContent>
          <Title>{t('transactions-h1')}</Title>

          <div className={cnTransactions('table')}>
            {!lessTablet ? (
              <Grid>
                <Row>
                  <Head>{t('ID')}</Head>
                  <Head>{t('Status')}</Head>
                  <Head>{t('Date')}</Head>
                  <Head>{t('Receive amount')}</Head>
                </Row>

                {!isLoading &&
                  data.map(transaction => (
                    <Row
                      key={transaction.id}
                      onClick={() => onClick(transaction.id)}
                    >
                      <Cell>{transaction.id}</Cell>
                      <Cell>
                        <Badge
                          icon={transaction.status.class}
                          title={t(transaction.status.title)}
                        />
                      </Cell>
                      <Cell>
                        {dayjs(transaction.date, 'DD.MM.YYYY').format(
                          'MMM DD, YYYY',
                        )}
                      </Cell>
                      <Cell>
                        <div className={cnTransactions('amount')}>
                          <div className={cnTransactions('value')}>
                            {transaction.get_sum}
                          </div>
                          <div className={cnTransactions('value')}>
                            {transaction.give_sum}
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  ))}
              </Grid>
            ) : (
              <>
                {!isLoading &&
                  data.map((transaction, i) => (
                    <Items
                      // direction={lessXMobile ? 'column' : undefined}
                      bottom={i + 1 !== data.length}
                    >
                      <Item small label={t('ID')}>
                        {transaction.id}
                      </Item>
                      <Item small label={t('Status')}>
                        <Badge
                          small
                          icon={transaction.status.class}
                          title={t(transaction.status.title)}
                        />
                      </Item>
                      <Item small label={t('Date')} mobile={lessXMobile}>
                        {dayjs(transaction.date, 'DD.MM.YYYY').format(
                          'MMM DD, YYYY',
                        )}
                      </Item>
                      <Item
                        small
                        mobile={lessXMobile}
                        label={t('Receive amount')}
                      >
                        <div className={cnTransactions('amount')}>
                          <div className={cnTransactions('value')}>
                            {transaction.get_sum}
                          </div>
                          <div className={cnTransactions('value')}>
                            {transaction.give_sum}
                          </div>
                        </div>
                      </Item>
                    </Items>
                  ))}
              </>
            )}

            {isLoading ? (
              <Footer>
                <Loading position='center' />
              </Footer>
            ) : (
              <>
                {transactions && transactions.total > transactions.per_page && (
                  <Footer>
                    <Pagination
                      total={transactions.total}
                      currentPage={transactions.current_page}
                      pageSize={transactions.per_page}
                      onPageChange={onPageChange}
                    />
                  </Footer>
                )}
                {transactions && transactions.total === 0 && (
                  <Footer>
                    <Empty>{t('You have no transactions :')}(</Empty>
                  </Footer>
                )}
              </>
            )}
          </div>
        </AppContent>

        {!lessDesktop && (
          <AppSidebar>
            <Exchange />
          </AppSidebar>
        )}
      </AppProfile>
    </>
  )
})
