import { FC, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'

import { toastSelector } from '@/core/services/Toast/store/ToastSelector'
import { ToastType } from '@/core/services/Toast/types/ToastType'
import { useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

export const Toast: FC = Memo(() => {
  const notification = useAppSelector(toastSelector)

  useEffect(() => {
    if (notification) {
      switch (notification?.type) {
        case ToastType.error:
          toast.error(notification.message)
          break
        case ToastType.success:
          toast.success(notification.message)
          break
        case ToastType.loading:
          toast.loading(notification.message)
          break
        case ToastType.custom:
          toast.custom(notification.message)
          break

        default:
          toast(notification.message)
          break
      }
    }
  }, [notification])

  return (
    <Toaster
      position={
        notification && notification.position
          ? notification.position
          : 'top-right'
      }
      toastOptions={{
        duration: 3000,
      }}
    />
  )
})
