import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { range } from '@/utils/range'

import { Icon } from '../Icon'
import './Step.scss'

const cnStep = cn('Step')

interface IStep {
  currentStep: number
  length: number
  className?: string
  isPrev?: boolean
  prev?: () => void
}

export const Step: FC<IStep> = ({
  currentStep,
  length,
  className,
  isPrev,
  prev,
}) => {
  const { t } = useTranslation('translation')

  const [arr, setArr] = useState<number[]>([])

  useEffect(() => {
    const list = range(1, length)

    setArr(list)
  }, [length])

  return (
    <div className={cnStep(null, [className])}>
      <div className={cnStep('top')}>
        <div className={cnStep('text')}>
          {isPrev && prev && (
            <button onClick={prev} className={cnStep('prev')}>
              <Icon
                name='chevron-left-solid'
                fill='#1e1f26'
                width={12}
                height={16}
              />
            </button>
          )}
          {t('Step')} {currentStep} {t('of')} {length}
        </div>

        <div className={cnStep('list')}>
          {arr.map(el => (
            <div
              key={el}
              className={cnStep('line', { active: el <= currentStep })}
            ></div>
          ))}
        </div>
      </div>
    </div>
  )
}
