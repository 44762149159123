import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import PhoneInput, { Country, Value } from 'react-phone-number-input'
import { Transition } from 'react-transition-group'
import { v4 as uuidv4 } from 'uuid'

import { geoSelector } from '@/core/services/App/store/AppSelector'
import { getGeolocationThunk } from '@/core/services/App/store/AppSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'
import { TypeMargin } from '@/core/types'

import useBackendError from '@/hooks/useBackendError'

import { CountrySelect } from '../CountrySelect'
import { Field } from '../Field'
import './FieldPhone.scss'

const cnFieldPhone = cn('FieldPhone')

export interface IFieldPhone {
  uid?: string
  label?: string
  name?: string
  errors?: string
  placeholder?: string
  // classNameInput?: string
  classNameLabel?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  // sizeInput?: TypeSize
  mb?: TypeMargin
  value?: Value
  className?: string
  onChange?: (val: string) => void
}

export const FieldPhone: FC<IFieldPhone> = ({
  uid = 'input-phone' + uuidv4(),
  value,
  label,
  name,
  required,
  errors,
  placeholder,
  readonly = false,
  className,
  classNameLabel,
  // classNameInput,
  disabled,
  // sizeInput = 'xxl',
  mb = 'sm',
  onChange,
}) => {
  const [setBackendError] = useBackendError()
  const dispatch = useAppDispatch()
  const geo = useAppSelector(geoSelector)

  const [countryCode, setCountryCode] = useState<Country | undefined>(undefined)

  const onChangeInput = (e: string) => {
    if (onChange) onChange(e)
  }

  const getGeolocation = async () => {
    try {
      await dispatch(getGeolocationThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  useEffect(() => {
    // if (geo && geo.location.country.alpha2) {
    //   setCountryCode(geo.location.country.alpha2)
    // }

    if (geo && geo.country) {
      setCountryCode(geo.country as Country)
    }
  }, [geo])

  useEffect(() => {
    getGeolocation()
  }, [])

  return (
    <div
      className={cnFieldPhone(
        {
          xxl: mb === 'xxl',
          xl: mb === 'xl',
          lg: mb === 'lg',
          md: mb === 'md',
          sm: mb === 'sm',
          none: mb === 'none',
        },
        [className],
      )}
    >
      {label && (
        <label
          htmlFor={uid}
          className={cnFieldPhone(
            'label',
            {
              required,
            },
            [classNameLabel],
          )}
        >
          {label}
        </label>
      )}

      <PhoneInput
        defaultCountry={countryCode}
        placeholder={placeholder}
        value={value as Value}
        onChange={onChangeInput}
        disabled={disabled}
        readOnly={readonly}
        id={uid}
        name={name}
        className={cnFieldPhone('wrap')}
        inputComponent={Field}
        countrySelectComponent={props => (
          <CountrySelect
            className={cnFieldPhone('select')}
            options={props.options}
            value={props.value}
            onChange={props.onChange}
            {...props}
          />
        )}
      />

      <Transition in={!!errors} timeout={500} mountOnEnter unmountOnExit>
        {(state: string) => (
          <div className={cnFieldPhone('error', [state])}>
            {/* <Icon name='alert-error' width={15} height={15} /> */}
            {errors}
          </div>
        )}
      </Transition>
    </div>
  )
}
