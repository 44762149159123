export class ExchangeModel {
  from?: string = ''
  to?: string = ''
  address?: string
  direction?: string
  payment_id?: number
}

export type ExchangeType = 'buy' | 'sell'
export type ExchangePaymentType = 'card' | 'sepa'

export interface ICourses {
  [key: string]: string
}

export interface ILimits {
  [key: string]: {
    min: number
    max: number
  }
}
export interface IResponseCourse {
  courses: ICourses
  cryptocurrencies: string[]
  fiats: string[]
  balances: ICourses
  limits: ILimits
  pay_methods: ICourses
  payments: ICourses
  types: string[]
}

export interface ICourseObg {
  id: number
  label: string
}

export interface ILimit {
  id: number
  label: string
  currency: string
  min: number
  max: number
}

export type IPaymentMethodType = 'Card' | 'Sepa'

export interface IPaymentMethod {
  id: number
  type: IPaymentMethodType
}
export interface IPayment {
  id: number
  direction: string
  methods: IPaymentMethod[]
}

export interface ITransformListCourse {
  id: number
  course: string
}
export interface ITransformCourses {
  direction: string
  list: ITransformListCourse[]
}
export interface ITransformCourse {
  method: ExchangeType
  data: ITransformCourses[]
}

export interface ICourse {
  courses: ITransformCourse[]
  cryptocurrencies: ICourseObg[]
  fiats: ICourseObg[]
  balances: ICourses
  limits: ILimit[]
  payMethods: ICourseObg[]
  payments: IPayment[]
  types: ICourseObg[]
}

export interface IRequestCalculation {
  from?: number
  to?: number
  direction: string
  payment_id: number
}

export interface IResponseCalculation {
  commission: number
  commission_code: string
  from: string
  to: string
}

export interface IActionExchange {
  type: ExchangeType
  give: string
  get?: string
}

export class IQueryOrder {
  action = ''
  address = ''
  direction = ''
  from = ''
  payment_id = ''
  payment_method = ''
  card?: string = ''

  constructor(
    action: string,
    address: string,
    direction: string,
    from: string,
    payment_id: string,
    payment_method: string,
    card: string,
  ) {
    this.action = action
    this.address = address
    this.direction = direction
    this.from = from
    this.payment_id = payment_id
    this.payment_method = payment_method
    this.card = card
  }
}

export interface IRequestExchange {
  sum: number
  direction: string
  payment_id: number
}

export interface IRequisiteExchange {
  required: boolean
  title: string
  name: string
}
// export interface IRequisitesExchange {
//   [key: string]: IRequisiteExchange
// }

export interface IResponseInfoExchanger {
  ip: string
  sum_pay: string
  sum_commision: string
  sum_equaring: string
  sym_pay: string
  sum_amount: string
  sum_buy: string
  sum_commission_all: string
  course_market: string
  course_sale: string
}
export interface IResponseInfoExchange {
  course: string
  exchanger: IResponseInfoExchanger
  requisites?: IRequisiteExchange[]
}

export interface IResponseExchange {
  email: string
  phone: string
  sum_buy: string
  info: IResponseInfoExchange
}

export interface IRequestLoadPaymentIframe {
  id: number
  theme?: 'light' | 'dark'
}

export interface IResponseLoadPaymentIframe {
  transaction: string
}
