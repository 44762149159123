import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'
import useWindowSize from '@/hooks/useWindowSize'

import yup from '@/utils/yup-extended'

import { AppContent, AppProfile, AppSidebar } from '@/components/AppProfile'
import { Avatar } from '@/components/Avatar'
import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { EditField } from '@/components/EditField'
import { Exchange } from '@/components/Exchange'
import { Field } from '@/components/Field'
import { FieldCode } from '@/components/FieldCode'
import { FieldPhone } from '@/components/FieldPhone'
import { Icon } from '@/components/Icon'
import { Switcher } from '@/components/Switcher'
import { Title } from '@/components/Title'
import { Upload } from '@/components/Upload'

import { IRequestTwoFA, UserModel } from '../Auth/models/UserModel'
import { twoFaSelector, userSelector } from '../Auth/store/AuthSelector'
import {
  changeTwoFAThunk,
  getCurrentUserThunk,
  updateAvatarThunk,
} from '../Auth/store/AuthSlice'
import './Settings.scss'

interface ICodeForm {
  code: string
}

const cnSettings = cn('Settings')

export const Settings: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const [setBackendError] = useBackendError()
  const { lessDesktop } = useWindowSize()
  const user = useAppSelector(userSelector)
  const twoFa = useAppSelector(twoFaSelector)

  const [data, setData] = useState<UserModel | null>(null)
  const [check, setCheck] = useState<boolean>(false)
  const [isDisabled, setDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (twoFa && twoFa.fa_enable) {
      setCheck(!!twoFa.fa_enable)
      setDisabled(false)
    } else {
      setCheck(false)
      setDisabled(true)
    }
  }, [twoFa])

  useEffect(() => {
    if (user) {
      setData(user)
    }
  }, [user])

  const getTwoFa = async () => {
    try {
      await dispatch(changeTwoFAThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const getCurrentUser = async () => {
    try {
      await dispatch(getCurrentUserThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  useEffect(() => {
    getTwoFa()
  }, [])

  const handleChange = async (file: File) => {
    const formData = new FormData()
    formData.append('avatar', file)

    try {
      await dispatch(updateAvatarThunk(formData)).unwrap()

      await getCurrentUser()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const schema = yup.object().shape({
    code: yup.string().trim().required(t('This field is required')),
  })

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<ICodeForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const handleOnChange = (e: string) => {
    if (e.length === 6) {
      setValue('code', e)
      setDisabled(false)
    }
  }

  const onSubmit = async (data: ICodeForm) => {
    const form: IRequestTwoFA = {
      is_enable: 1,
    }
    if (twoFa && twoFa.fa_enable) {
      form.is_enable = 0
      form.code = data.code
    } else {
      form.code = data.code
    }

    try {
      await dispatch(changeTwoFAThunk(form)).unwrap()

      await dispatch(changeTwoFAThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('settings-title')}</title>
        <meta name='description' content={t('settings-description')} />
      </Helmet>

      <AppProfile>
        <AppContent>
          <Title>{t('settings-h1')}</Title>

          {user && (
            <div className={cnSettings('wrap')}>
              <div className={cnSettings('left')}>
                <Card className={cnSettings('card')}>
                  <div className={cnSettings('el')}>
                    <div className={cnSettings('label')}>
                      {t('Profile Image')}
                    </div>
                    <div className={cnSettings('upload')}>
                      <Avatar
                        src={user.avatar || ''}
                        className={cnSettings('avatar')}
                      />
                      <Upload name='file' handleChange={handleChange} />
                    </div>
                  </div>

                  {data && (
                    <>
                      <div className={cnSettings('el')}>
                        <EditField label={t('Email')} value={data.email}>
                          <Field
                            type='email'
                            name='email'
                            mb='none'
                            value={data.email}
                          />
                        </EditField>
                      </div>

                      <div className={cnSettings('el')}>
                        <EditField label={t('Phone')} value={data.phone}>
                          <FieldPhone
                            name='phone'
                            mb='none'
                            value={data.phone}
                          />
                        </EditField>
                      </div>
                    </>
                  )}
                </Card>

                {!!user.is_verify && (
                  <Card className={cnSettings('card')}>
                    <div className={cnSettings('el')}>
                      <div className={cnSettings('label')}>
                        {t('First Name')}
                      </div>
                      <div className={cnSettings('value')}>
                        {user.first_name}
                      </div>
                    </div>

                    <div className={cnSettings('el')}>
                      <div className={cnSettings('label')}>
                        {t('Last Name')}
                      </div>
                      <div className={cnSettings('value')}>
                        {user.last_name}
                      </div>
                    </div>

                    <div className={cnSettings('el')}>
                      <div className={cnSettings('label')}>
                        {t('Birth Date')}
                      </div>
                      <div className={cnSettings('value')}>{user.birthday}</div>
                    </div>
                  </Card>
                )}
              </div>
              <div className={cnSettings('right')}>
                <Card className={cnSettings('card')}>
                  <div className={cnSettings('el')}>
                    <div className={cnSettings('title')}>
                      {t('Privacy and Security')}
                    </div>
                  </div>
                  {/* <div className={cnSettings('el')}>
                    <div className={cnSettings('label')}>
                      {t('Use passcode to confirm transfers')}
                    </div>
                  </div> */}
                  <div className={cnSettings('el', { flex: true })}>
                    <div className={cnSettings('label', { bn: true })}>
                      {t('Use two factor authentication')}
                    </div>

                    <Switcher
                      className={cnSettings('switch')}
                      isChecked={check}
                      disabled={!!twoFa?.fa_enable}
                      handleCustomChange={e => setCheck(e as boolean)}
                    />
                  </div>

                  {twoFa && check && (
                    <form
                      className={cnSettings('el')}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className={cnSettings('label')}>
                        {t('Secret Key')}
                      </div>
                      <div className={cnSettings('value')}>{twoFa.secret} </div>

                      <div className={cnSettings('qr')}>
                        <div className={cnSettings('label')}>
                          {t('QR COde')}
                        </div>
                        <div className={cnSettings('img')}>
                          <img src={twoFa.qr} alt={twoFa.secret} />
                        </div>

                        <div className={cnSettings('code')}>
                          <FieldCode
                            key={twoFa.fa_enable}
                            handleOnChange={handleOnChange}
                            isTime={false}
                            error={errors && !!errors.code}
                          />

                          <div className={cnSettings('submit')}>
                            <Button
                              size='md'
                              className={cnSettings('save')}
                              icon={<Icon name='check-white' />}
                              iconPosition='right'
                              type='submit'
                              isLoading={isSubmitting}
                              disabled={isDisabled}
                              fluid
                            >
                              {t(twoFa.fa_enable ? 'Reset' : 'Save')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Card>
                {/* <Card className={cnSettings('card')}>
                  <div className={cnSettings('el')}>
                    <div className={cnSettings('title')}>
                      {t('System Preferences')}
                    </div>
                  </div>

                  <div className={cnSettings('el')}>
                    <div className={cnSettings('label')}>{t('Language')}</div>
                  </div>
                </Card> */}
              </div>
            </div>
          )}
        </AppContent>
        {!lessDesktop && (
          <AppSidebar>
            <Exchange />
          </AppSidebar>
        )}
      </AppProfile>
    </>
  )
})
