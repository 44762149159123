import { cn } from '@bem-react/classname'
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import { Button } from '../Button'
import './EditField.scss'

const cnEditField = cn('EditField')

interface IEditField
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: ReactNode
  value?: string | number
  label?: string
  save?: () => void
}

export const EditField: FC<IEditField> = Memo(
  ({ children, className, value, label, save }: IEditField) => {
    const { t } = useTranslation('translation')

    const [open, setOpen] = useState<boolean>(false)

    const onClick = () => {
      if (open && save) {
        save()
      }
      setOpen(!open)
    }

    return (
      <div className={cnEditField(null, [className])}>
        <div className={cnEditField('wrap', { edit: open })}>
          {label && <label className={cnEditField('label')}>{label}</label>}

          {open ? (
            children
          ) : (
            <div className={cnEditField('value')}>{value}</div>
          )}
        </div>

        <Button
          color='gray'
          onClick={onClick}
          className={cnEditField('btn', { open })}
          size='md'
        >
          {t(open ? 'Save' : 'Edit')}
        </Button>
      </div>
    )
  },
)
