import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { Memo } from '@/hoc/Memo'

import './styles/Title.scss'

const cnTitle = cn('Title')

interface ITitle
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  small?: boolean
}

export const Title: FC<ITitle> = Memo(
  ({ children, className, small }: ITitle) => {
    return <div className={cnTitle({ small }, [className])}>{children}</div>
  },
)
