import { useEffect, useState } from 'react'

export interface WindowSizeSchema {
  width: number
  height: number
  notebook: boolean
  desktop: boolean
  ipad: boolean
  tablet: boolean
  tabletOrMobile: boolean
  tabletOrRetina: boolean
  tabletOrXmobile: boolean
  mtablet: boolean
  mobile: boolean
  xmobile: boolean
  retina: boolean
  lessDesktop: boolean
  lessIpad: boolean
  lessMTablet: boolean
  lessTablet: boolean
  lessMobile: boolean
  lessXMobile: boolean
  lessRetina: boolean
}

const useWindowSize = (): WindowSizeSchema => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSizeSchema>({
    width: 0,
    height: 0,
    notebook: false,
    desktop: false,
    ipad: false,
    mtablet: false,
    tablet: false,
    tabletOrMobile: false,
    tabletOrRetina: false,
    tabletOrXmobile: false,
    mobile: false,
    xmobile: false,
    retina: false,
    lessDesktop: false,
    lessIpad: false,
    lessMTablet: false,
    lessTablet: false,
    lessMobile: false,
    lessXMobile: false,
    lessRetina: false,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        notebook: window.innerWidth <= 1366,
        desktop: window.innerWidth < 1200 && window.innerWidth > 1024,
        ipad: window.innerWidth < 1024 && window.innerWidth > 992,
        mtablet: window.innerWidth < 992 && window.innerWidth > 768,
        tablet: window.innerWidth < 768 && window.innerWidth < 576,
        tabletOrMobile: window.innerWidth < 768 && window.innerWidth >= 576,
        tabletOrXmobile: window.innerWidth < 768 && window.innerWidth >= 480,
        tabletOrRetina: window.innerWidth <= 768 && window.innerWidth >= 320,
        mobile: window.innerWidth < 576 && window.innerWidth < 480,
        xmobile: window.innerWidth < 480 && window.innerWidth < 414,
        retina: window.innerWidth < 414 && window.innerWidth >= 320,
        lessDesktop: window.innerWidth <= 1200,
        lessIpad: window.innerWidth <= 992,
        lessMTablet: window.innerWidth <= 992,
        lessTablet: window.innerWidth <= 768,
        lessMobile: window.innerWidth <= 576,
        lessXMobile: window.innerWidth <= 480,
        lessRetina: window.innerWidth <= 414 && window.innerWidth >= 320,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export default useWindowSize
