import { cn } from '@bem-react/classname'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import { TypeSize } from '@/core/types'

import { Memo } from '@/hoc/Memo'

import { Button } from '../Button'
import './Upload.scss'

const cnUpload = cn('Upload')

interface IUpload
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  text?: string
  accept?: string
  handleChange: (file: File) => void
  size?: TypeSize
}

export const Upload: FC<IUpload> = Memo(
  ({
    className,
    name,
    text = 'Upload Image',
    accept = 'image/png,image/gif,image/jpeg,image/webp',
    size = 'md',
    handleChange,
  }: IUpload) => {
    const { t } = useTranslation('translation')

    const fileRef = useRef<HTMLInputElement | null>(null)

    const onClick = () => {
      if (fileRef && fileRef.current) {
        fileRef.current.click()
      }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0]

      if (!file) {
        return
      }

      handleChange(file)
    }

    return (
      <div className={cnUpload(null, [className])}>
        <input
          ref={fileRef}
          type='file'
          name={name}
          onChange={handleFileChange}
          accept={accept}
          className={cnUpload('input')}
        />

        <Button color='gray' onClick={onClick} size={size}>
          {t(text)}
        </Button>
      </div>
    )
  },
)
