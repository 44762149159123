import { cn } from '@bem-react/classname'
import { PureComponent, forwardRef } from 'react'

import { ITransactionManual } from '@/modules/Transactions/models/TransactionsModel'

import { Logo } from '../Logo'
import './ComponentToPrint.scss'

const cnComponentToPrint = cn('ComponentToPrint')

type Props = {
  manual: ITransactionManual[]
}

export class ComponentToPrint extends PureComponent<Props> {
  constructor(props: Props) {
    super(props)
  }

  private canvasEl!: HTMLCanvasElement

  public componentDidMount() {
    const ctx = this.canvasEl.getContext('2d')
    if (ctx) {
      ctx.beginPath()
      ctx.arc(95, 50, 40, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.fillStyle = 'rgb(200, 0, 0)'
      ctx.fillRect(85, 40, 20, 20)
      ctx.save()
    }
  }

  private setRef = (ref: HTMLCanvasElement) => (this.canvasEl = ref)

  public render() {
    const { manual } = this.props

    return (
      <div className={cnComponentToPrint()}>
        <canvas ref={this.setRef} style={{ display: 'none' }}>
          Your browser does not support the HTML5 canvas tag.
        </canvas>
        {/* <link
          // This tests that we properly ignore disabled nodes
          // Learn more: https://github.com/gregnb/react-to-print/pull/537
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          disabled
          rel='stylesheet'
          href='../disabled.css'
        /> */}
        <style type='text/css' media='print'>
          {'@page { size: landscape; }'}
        </style>

        <div className={cnComponentToPrint('top')}>
          <Logo />
        </div>

        <div className={cnComponentToPrint('wrap')}>
          {manual.map((el, i) => (
            <div key={i} className={cnComponentToPrint('block')}>
              <div className={cnComponentToPrint('title')}>{el.title}</div>
              <div className={cnComponentToPrint('value')}>{el.value}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export const FunctionalComponentToPrint = forwardRef<
  ComponentToPrint | null,
  Props
>((props, ref) => {
  // eslint-disable-line max-len
  return <ComponentToPrint ref={ref} manual={props.manual} />
})
