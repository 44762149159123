import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

export const PageNotFound: FC = Memo(() => {
  const { t } = useTranslation('translation')
  return (
    <div>
      <div>{t('Page not found')}</div>
    </div>
  )
})
