import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IResponseDefaultData } from '@/core/types'

import TransactionsService from '../TransactionsService'
import { ITransaction } from '../models/TransactionsModel'

export interface TransactionsState {
  transactions: IResponseDefaultData<ITransaction[]> | null
  transaction: ITransaction | null
}

const initialState: TransactionsState = {
  transactions: null,
  transaction: null,
}

export const getTransactionsThunk = createAsyncThunk(
  'transactions/getTransactionsThunk',
  async (page: number, { rejectWithValue }) => {
    try {
      return await TransactionsService.getTransactions(page)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getTransactionThunk = createAsyncThunk(
  'transactions/getTransactionThunk',
  async (id: number, { rejectWithValue }) => {
    try {
      return await TransactionsService.getTransaction(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const cancelOrderThunk = createAsyncThunk(
  'transactions/cancelOrderThunk',
  async (id: number, { rejectWithValue }) => {
    try {
      return await TransactionsService.cancelOrder(id)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    resetTransaction(state) {
      state.transaction = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTransactionsThunk.fulfilled, (state, action) => {
        state.transactions = action.payload
      })

      .addCase(getTransactionsThunk.rejected, state => {
        state.transactions = null
      })

      .addCase(getTransactionThunk.fulfilled, (state, action) => {
        state.transaction = action.payload
      })

      .addCase(getTransactionThunk.rejected, state => {
        state.transaction = null
      })
  },
})

export const { actions, reducer } = transactionsSlice
export const { resetTransaction } = actions
