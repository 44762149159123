import { cn } from '@bem-react/classname'
import { useClickOutsideListener } from '@patrissoljuns/react-click-outside'
import { FC, MouseEvent, useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import { Country, getCountryCallingCode } from 'react-phone-number-input/input'

import { Icon } from '../Icon'
import './CountrySelect.scss'

const cnCountrySelect = cn('CountrySelect')

interface ICountrySelect {
  className?: string
  classNameList?: string
  classNameItem?: string
  value?: string
  // onFocus?: (e: FocusEvent<HTMLButtonElement>) => void
  onChange: (value: string) => void
  options: Array<{ value: string; label: string }>
}

export const CountrySelect: FC<ICountrySelect> = ({
  className,
  classNameList,
  classNameItem,
  value,
  onChange,
  // onFocus,
  options,
  ...rest
}: ICountrySelect) => {
  const { i18n } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')
  const [country, setCountry] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [labels, setLabels] = useState<any>()

  const [val, setVal] = useState<string>('')
  const [scrollbars, setScrollbars] = useState<Scrollbars | null>(null)
  const containerRef = useClickOutsideListener<HTMLDivElement>({
    onClickOutside: () => setOpen(false),
    events: ['keydown', 'touchstart'],
  })

  const loadLocale = async (language: string) => {
    const locale = await import(
      `~/node_modules/react-phone-number-input/locale/${language}.json`
    )

    setLabels(locale)
  }

  useEffect(() => {
    if (i18n.language) {
      loadLocale(i18n.language)
    }
  }, [i18n])

  useEffect(() => {
    if (value) {
      setCountry(value)
      setSelected(`+${getCountryCallingCode(value as Country)}`)
      setVal(value)
    }
    //  else {
    //   const current = options[1]

    //   console.log('current', current)
    //   if (current) {
    //     setCountry(current.value)
    //     setSelected(generateCode(current.value as Country))
    //     onChange && onChange(current.value)
    //   }
    // }
  }, [value])

  const select = (country: string) => {
    setSelected(`+${getCountryCallingCode(country as Country)}`)
    setCountry(country)
    setOpen(false)
    setVal(country)
    onChange(country)
  }

  const generateCode = (value: Country) => {
    return `+${getCountryCallingCode(value)}`
  }

  const openList = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(!open)
  }

  useEffect(() => {
    if (scrollbars && value) {
      const index = options.map(el => el.value).indexOf(value)

      scrollbars.scrollTop(index * 40 - 40)
    }
  }, [value, scrollbars, options])

  // const onChange_ = (event: ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value

  //   console.log('onChange_', value)

  //   onChange(value)
  // }

  // console.log('value', value)
  // console.log('val', val)

  return (
    <>
      <button
        type='button'
        className={cnCountrySelect('selected', [className])}
        onClick={openList}
      >
        {selected}
        <Icon name='arrow-down' fill='white' />
      </button>

      {labels && open && (
        <div
          ref={containerRef}
          className={cnCountrySelect('list', [classNameList])}
        >
          <input type='hidden' {...rest} value={val} />
          <Scrollbars
            className={cnCountrySelect('scroll')}
            ref={s => setScrollbars(s)}
          >
            {options
              .filter((_, i) => i > 0)
              .map(c => (
                <button
                  key={c.value}
                  type='button'
                  className={cnCountrySelect(
                    'item',
                    { selected: country === c.value },
                    [classNameItem],
                  )}
                  onClick={() => {
                    select(c.value)
                  }}
                >
                  <strong className={cnCountrySelect('code')}>
                    {generateCode(c.value as Country)}
                  </strong>
                  {labels[c.value]}
                </button>
              ))}
          </Scrollbars>
        </div>
      )}
    </>
  )
}
