import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { Memo } from '@/hoc/Memo'

import './Card.scss'

const cnCard = cn('Card')

interface ICard
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  resetStyle?: boolean
}

export const Card: FC<ICard> = Memo(
  ({ children, className, resetStyle }: ICard) => {
    return (
      <div className={cnCard({ reset: resetStyle }, [className])}>
        {children}
      </div>
    )
  },
)
