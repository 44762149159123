import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const user = (state: RootState) => state.authReducer
export const userSelector = createSelector(
  user,
  authReducer => authReducer.user,
)

const twoFa = (state: RootState) => state.authReducer
export const twoFaSelector = createSelector(
  twoFa,
  authReducer => authReducer.twoFa,
)
