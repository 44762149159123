import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import './AuthWrap.scss'

const cnAuthWrap = cn('AuthWrap')

interface IAuthWrap
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
}

export const AuthWrap: FC<IAuthWrap> = ({ children, className }) => {
  return <div className={cnAuthWrap(null, [className])}>{children}</div>
}
