import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import './AppProfile.scss'

const cnAppProfile = cn('AppProfile')

interface IAppSidebar
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  padding?: boolean
}

export const AppSidebar: FC<IAppSidebar> = ({
  children,
  className,
  padding,
}: IAppSidebar) => {
  return (
    <div className={cnAppProfile('sidebar', { padding }, [className])}>
      {children}
    </div>
  )
}
