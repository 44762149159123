import { cn } from '@bem-react/classname'
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Memo } from '@/hoc/Memo'

import './Checkbox.scss'

const cnCheckbox = cn('Checkbox')

export type CheckboxType = 'checkbox' | 'radio'
export type CheckboxColorType = 'dark' | 'blue'

export interface CheckboxSchema
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  uid?: string
  type?: CheckboxType
  label?: string
  isChecked?: boolean
  disabled?: boolean
  color?: CheckboxColorType
  handleCustomChange?: (e: boolean | number | string) => void
  classNameLabel?: string
  children?: ReactNode
  errors?: string
}

export const Checkbox: FC<CheckboxSchema> = Memo(
  forwardRef(
    (
      {
        type = 'checkbox',
        uid = `${type}-` + uuidv4(),
        onChange,
        handleCustomChange,
        label,
        name,
        disabled,
        className,
        isChecked,
        color = 'blue',
        classNameLabel,
        children,
        errors,
        ...props
      }: CheckboxSchema,
      ref: ForwardedRef<HTMLInputElement>,
    ) => {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (isChecked !== undefined && handleCustomChange) {
          handleCustomChange(e.target.checked)
        } else if (handleCustomChange) {
          handleCustomChange(e.target.value)
        } else if (onChange) {
          onChange(e)
        }
      }

      return (
        <div className={cnCheckbox(null, [className])}>
          <input
            name={name}
            type={type}
            id={uid}
            className={cnCheckbox('input')}
            onChange={handleChange}
            checked={isChecked}
            disabled={disabled}
            ref={ref}
            {...props}
          />
          <label
            htmlFor={uid}
            className={cnCheckbox(
              'label',
              {
                disabled,
                dark: color === 'dark',
                blue: color === 'blue',
                error: !!errors,
              },
              [classNameLabel],
            )}
          >
            {label ? (
              <span className={cnCheckbox('text', { error: !!errors })}>
                {label}
              </span>
            ) : (
              <span className={cnCheckbox('text', { error: !!errors })}>
                {children}
              </span>
            )}
          </label>
        </div>
      )
    },
  ),
)
