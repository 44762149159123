import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { directionSelector } from '@/core/services/App/store/AppSelector'
import { changeDirectionsExchange } from '@/core/services/App/store/AppSlice'
import { IResponseDirection } from '@/core/services/Wallets/WalletsModel'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/Button'

import './Currency.scss'

const cnCurrency = cn('Currency')

interface ICurrency
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  element: IResponseDirection
  currency: string
}

export const Currency: FC<ICurrency> = ({ className, element, currency }) => {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { lessDesktop } = useWindowSize()
  const direction = useAppSelector(directionSelector)

  const onClick = (type: string) => {
    if (lessDesktop) {
      navigate('/profile/exchange')
    }

    if (direction) {
      const d =
        type === 'Buy'
          ? direction.direction.split('/')
          : direction.direction.split('/').reverse()

      const str =
        type === 'Buy' ? `${d[0]}/${element.code}` : `${element.code}/${d[1]}`

      dispatch(
        changeDirectionsExchange({
          type,
          direction: str,
        }),
      )
    }
  }

  return (
    <div className={cnCurrency(null, [className])}>
      <div className={cnCurrency('first')}>
        <div className={cnCurrency('icon')}>
          <span className={`icon-${element.code.toUpperCase()}`}></span>
        </div>

        <div className={cnCurrency('text')}>
          <div className={cnCurrency('small')}>{element.code}</div>
          <div className={cnCurrency('name')}>{element.title}</div>
        </div>
      </div>

      {/* <div className={cnCurrency('graph')}></div> */}
      <div className={cnCurrency('actions')}>
        <div
          className={cnCurrency('value', {
            down: +element.course < +element.old_course,
          })}
        >
          {currency}
          {parseFloat(element.course)}
        </div>

        {!!element.type.is_buy && (
          <Button color='outline' size='sm' onClick={() => onClick('Buy')}>
            {t('button-buy')}
          </Button>
        )}
        {!!element.type.is_sell && (
          <Button color='outline' size='sm' onClick={() => onClick('Sell')}>
            {t('button-sell')}
          </Button>
        )}
      </div>
    </div>
  )
}
