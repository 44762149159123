import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, useEffect, useRef } from 'react'
import AuthCode, { AuthCodeRef } from 'react-auth-code-input'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import useCountDown from '@/hooks/useCountDown'

import { Loading } from '../Loading'
import './FieldCode.scss'

const cnFieldCode = cn('FieldCode')

type AllowedCharactersType = 'alpha' | 'numeric' | 'alphanumeric'

interface IFieldCode
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isTime?: boolean
  error?: boolean
  disabled?: boolean
  loading?: boolean
  length?: number
  label?: string
  placeholder?: string
  isPassword?: boolean
  autoFocus?: boolean
  allowedCharacters?: AllowedCharactersType
  success?: boolean
  containerClassName?: string
  handleOnChange: (code: string) => void
  resendCode?: () => void
}

export const FieldCode: FC<IFieldCode> = Memo(
  ({
    isTime = true,
    className,
    containerClassName,
    error,
    length = 6,
    label,
    disabled,
    placeholder,
    isPassword,
    autoFocus = true,
    allowedCharacters = 'numeric',
    success,
    loading,
    handleOnChange,
    resendCode,
  }: IFieldCode) => {
    const { t } = useTranslation('translation')
    const AuthInputRef = useRef<AuthCodeRef>(null)

    const { time, stopTimer, startTimer } = useCountDown({})

    useEffect(() => {
      if (isTime) startTimer(120)
    }, [isTime])

    useEffect(() => {
      if (success || error) {
        stopTimer()
      }
    }, [success])

    const resend = () => {
      resendCode && resendCode()

      if (AuthInputRef.current) {
        AuthInputRef.current.clear()
      }

      startTimer(120)
    }

    return (
      <div className={cnFieldCode(null, [className])}>
        {label && <label className={cnFieldCode('label')}>{label}</label>}
        <AuthCode
          length={length}
          onChange={handleOnChange}
          ref={AuthInputRef}
          containerClassName={cnFieldCode('container', [containerClassName])}
          inputClassName={cnFieldCode('input', { error, success })}
          disabled={disabled}
          placeholder={placeholder}
          isPassword={isPassword}
          autoFocus={autoFocus}
          allowedCharacters={allowedCharacters}
        />

        {(time || loading || success || error || isTime) && (
          <div className={cnFieldCode('bottom')}>
            {isTime && (
              <>
                {time ? (
                  <div className={cnFieldCode('resend')}>
                    {t('Resend code in')} {time}
                  </div>
                ) : (
                  <button
                    type='button'
                    onClick={resend}
                    className={cnFieldCode('resend')}
                  >
                    {t('Resend code')}
                  </button>
                )}
              </>
            )}

            {loading && (
              <div className={cnFieldCode('loading')}>
                <Loading className={cnFieldCode('icon')} />
                {t('Checking...')}
              </div>
            )}

            {success && (
              <div className={cnFieldCode('success')}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L16.073 6.929L14.659 5.515L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z'
                    fill='#38A169'
                  />
                </svg>

                {t('Correct code')}
              </div>
            )}

            {error && (
              <div className={cnFieldCode('error')}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z'
                    fill='#E53E3E'
                  />
                </svg>

                {t('Incorrect code')}
              </div>
            )}
          </div>
        )}
      </div>
    )
  },
)
