import { AxiosResponse } from 'axios'

import { http } from '@/utils/http'
import { setItem } from '@/utils/persistanceStorage'

import {
  RegisterModel,
  RegisterRequestCodeModel,
  RegisterResponceCodeModel,
  RegisterResponseModel,
} from '../models/RegisterModel'

class RegisterService {
  async registration(data: RegisterModel): Promise<RegisterResponseModel> {
    const res = await http.post<
      RegisterModel,
      AxiosResponse<RegisterResponseModel>
    >(`/auth/registration`, data)

    if (res.data && res.data.token) {
      setItem('token', res.data.token, false)
    }

    return res.data
  }

  async getCode(
    data: RegisterRequestCodeModel,
  ): Promise<RegisterResponceCodeModel> {
    const { input, ...rest } = data

    const res = await http.post<
      RegisterRequestCodeModel,
      AxiosResponse<RegisterResponceCodeModel>
    >(`/auth/registration/code/${input}`, rest)

    return res.data
  }
}

export default new RegisterService()
