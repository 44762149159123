import { cn } from '@bem-react/classname'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

import useWindowSize from '@/hooks/useWindowSize'

import { Icon } from '@/components/Icon'

import './Menu.scss'

const cnMenu = cn('Menu')

interface IMenu {
  className?: string
}

export const Menu: FC<IMenu> = ({ className }) => {
  const { t } = useTranslation('translation')
  const { pathname } = useLocation()
  const { lessDesktop, lessTablet } = useWindowSize()

  const [data, setData] = useState([
    {
      to: '/profile',
      icon: 'menu-home',
      title: 'menu-home',
    },

    {
      to: '/profile/transactions',
      icon: 'menu-history',
      title: 'menu-history',
    },
    {
      to: '/profile/settings',
      icon: 'menu-settings',
      title: 'menu-settings',
    },
  ])

  useEffect(() => {
    if (lessDesktop) {
      const prev = data.filter((_, i) => i < 1)
      const next = data.filter((_, i) => i >= 1)

      const arr = [
        ...prev,
        {
          to: '/profile/exchange',
          icon: 'menu-exchange',
          title: 'menu-exchange',
        },
        ...next,
      ]

      setData(arr)
    } else {
      setData(data.filter(el => el.title !== 'menu-exchange'))
    }
  }, [lessDesktop])

  return (
    <nav className={cnMenu(null, [className])}>
      <ul className={cnMenu('list')}>
        {data.map(el => (
          <li key={el.icon} className={cnMenu('item')}>
            <NavLink
              to={el.to}
              className={cnMenu('link', { active: pathname === el.to })}
            >
              <Icon
                name={el.icon}
                className={cnMenu('icon')}
                width={25}
                height={25}
              />

              {!lessTablet && (
                <>
                  {t(el.title)}
                  <Icon name='arrow-right-small' className={cnMenu('arrow')} />
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
