import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    returnNull: false,
    fallbackLng: {
      it: ['it'],
      en: ['en'],
      ru: ['ru'],
      default: ['en'],
    },
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },

    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'lang',
    },

    backend: {
      loadPath: '/i18n/{{lng}}/{{ns}}.json',
    },
  })

export default i18n
