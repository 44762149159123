import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const wallets = (state: RootState) => state.walletsReducer
export const walletsSelector = createSelector(
  wallets,
  walletsReducer => walletsReducer.wallets,
)

const courses = (state: RootState) => state.walletsReducer
export const coursesDaySelector = createSelector(
  courses,
  walletsReducer => walletsReducer.courses,
)

const directions = (state: RootState) => state.walletsReducer
export const directionsSelector = createSelector(
  directions,
  walletsReducer => walletsReducer.directions,
)
