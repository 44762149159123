import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import { AppContent, AppProfile } from '@/components/AppProfile'
// import { Back } from '@/components/Back'
import { Exchange } from '@/components/Exchange'
import { Title } from '@/components/Title'

import './ExchangePage.scss'

const cnExchangePage = cn('ExchangePage')

export const ExchangePage: FC = Memo(() => {
  const { t } = useTranslation('translation')

  return (
    <>
      <Helmet>
        <title>{t('Exchange')}</title>
        <meta name='description' content={''} />
      </Helmet>
      <AppProfile>
        <AppContent>
          <div className={cnExchangePage('top')}>
            <Title>{t('Exchange')}</Title>
            {/* <Back text={t('Back')} /> */}
          </div>
          <Exchange isPage className={cnExchangePage('wrap')} />
        </AppContent>
      </AppProfile>
    </>
  )
})
