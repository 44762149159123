export class LoginModel {
  phone?: string = ''
  email?: string = ''
  code?: string = ''
  confirm_code?: string = ''
  code_2fa?: string = ''
}

export class LoginResponseModel {
  is_2fa?: number = 0
  is_verify?: number = 0
  token?: string = ''
}

export class LoginRequestCodeModel {
  phone?: string = ''
  email?: string = ''
  type: 'email' | 'phone' = 'email'
}
