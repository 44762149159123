import { cn } from '@bem-react/classname'
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

// import { useNavigate } from 'react-router-dom'
import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Back.scss'

const cnBack = cn('Back')

interface IBack
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  to?: string
  text?: string
  mr?: boolean
}

export const Back: FC<IBack> = Memo(
  ({ to, text, className, mr, onClick }: IBack) => {
    const navigate = useNavigate()

    const click = (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event)

        return
      }
      if (to) {
        navigate(to)
      } else {
        navigate(-1)
      }
    }

    return (
      <button onClick={click} className={cnBack({ mr }, [className])}>
        <Icon name='arrow-left' />

        {text && <span className={cnBack('text')}> {text}</span>}
      </button>
    )
  },
)
