import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const notifications = (state: RootState) => state.notificationsReducer
export const notificationsSelector = createSelector(
  notifications,
  notificationsReducer => notificationsReducer.notifications,
)

const count = (state: RootState) => state.notificationsReducer
export const countNotificationsSelector = createSelector(
  count,
  notificationsReducer => notificationsReducer.count,
)

const last = (state: RootState) => state.notificationsReducer
export const lastNotificationsSelector = createSelector(
  last,
  notificationsReducer => notificationsReducer.last,
)
