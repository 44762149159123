import { cn } from '@bem-react/classname'
import { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import { useAppDispatch } from '@/core/store/hooks'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/Button'
import { FunctionalComponentToPrint } from '@/components/ComponentToPrint'
import { Icon } from '@/components/Icon'
import { Item, Items } from '@/components/Items'

import { ITransaction } from '../../models/TransactionsModel'
import { cancelOrderThunk } from '../../store/TransactionsSlice'
import './SepaTransfer.scss'

const cnSepaTransfer = cn('SepaTransfer')

interface ISepaTransfer {
  transaction: ITransaction
  className?: string
  cancel?: () => void
}

export const SepaTransfer: FC<ISepaTransfer> = ({
  transaction,
  className,
  cancel,
}) => {
  const { t } = useTranslation('translation')
  const { width } = useWindowSize()
  const navigate = useNavigate()
  const componentRef = useRef(null)
  const dispatch = useAppDispatch()

  const [isLoading, setLoading] = useState<boolean>(false)

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  })

  const changeRoute = () => {
    navigate('/profile/transactions')
  }

  const cancelOrder = async () => {
    setLoading(true)
    try {
      await dispatch(cancelOrderThunk(transaction.id)).unwrap()
      cancel && cancel()
      // setLoading(false)
    } catch (error) {
      console.log('cancelOrder', error)
      setLoading(false)
    }
  }

  return (
    <div className={cnSepaTransfer(null, [className])}>
      <Items className={cnSepaTransfer('list')}>
        <Item mobile={width <= 480} label={t('Status')}>
          <div
            className={cnSepaTransfer(
              'value',

              {
                success: transaction.status.class === 'success',
                warning: transaction.status.class === 'warning',
                danger: transaction.status.class === 'danger',
              },
            )}
          >
            {transaction.status.title}
          </div>
        </Item>
        {transaction.manual &&
          transaction.manual.map((el, i) => (
            <Item
              mobile={width <= 480}
              key={i}
              label={t(el.title)}
              value={el.value}
              // full={i % 3 === 0 && i + 1 === transaction.manual?.length}
            />
          ))}

        {transaction.tx && transaction.tx_url && (
          <Item
            label={t('TRX ID')}
            full
            mobile={width <= 480}
            // value={transaction.commission_sum + ' ' + transaction.give}
          >
            <a
              href={transaction.tx_url}
              target='_blank'
              className={cnSepaTransfer('link')}
            >
              {t('View explorer')}

              <Icon name='explorer' />
            </a>
            <div className={cnSepaTransfer('tx')}>{transaction.tx}</div>
          </Item>
        )}
      </Items>

      {transaction.status.title === 'New' && (
        <div className={cnSepaTransfer('btns')}>
          <Button
            className={cnSepaTransfer('btn')}
            size='xl'
            onClick={changeRoute}
            disabled={isLoading}
          >
            {t('Confirm')}
          </Button>

          <Button
            className={cnSepaTransfer('btn')}
            size='xl'
            onClick={handlePrint}
            color='gray'
            disabled={isLoading}
          >
            {t('Print')}
          </Button>
          <Button
            className={cnSepaTransfer('btn')}
            size='xl'
            onClick={cancelOrder}
            color='outline'
            isLoading={isLoading}
          >
            {t('Cancel')}
          </Button>
        </div>
      )}

      {transaction && transaction.manual && (
        <div className={cnSepaTransfer('print')}>
          <FunctionalComponentToPrint
            ref={componentRef}
            manual={transaction.manual}
          />
        </div>
      )}
    </div>
  )
}
