import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import dayjs from '@/utils/dayjs'

import { Button } from '@/components/Button'
import { Icon } from '@/components/Icon'
import { Item, Items } from '@/components/Items'

import { ITransaction } from '../../models/TransactionsModel'
import './TransactionDetail.scss'

const cnTransactionDetail = cn('TransactionDetail')

interface ITransactionDetail {
  transaction: ITransaction
  className?: string
}

export const TransactionDetail: FC<ITransactionDetail> = ({
  transaction,
  className,
}) => {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()

  const pay = () => {
    if (!transaction) return

    navigate(`/profile/payment/${transaction.id}`)
  }

  return (
    <div className={cnTransactionDetail(null, [className])}>
      <Items>
        <Item
          label={t('Date')}
          value={dayjs(transaction.date, 'DD.MM.YYYY').format('MMM DD, YYYY')}
        />

        <Item label={t('ID')} value={transaction.id} />

        <Item label={t('Status')}>
          <div
            className={cnTransactionDetail(
              'value',

              {
                success: transaction.status.class === 'success',
                warning: transaction.status.class === 'warning',
                danger: transaction.status.class === 'danger',
              },
            )}
          >
            {transaction.status.title}
          </div>
        </Item>

        {transaction.wallet && (
          <Item
            label={transaction.get + ' ' + t('Address')}
            value={transaction.wallet}
          />
        )}

        <Item
          label={t('Payment Amount') + ' ' + transaction.give}
          value={transaction.give_sum}
        />

        <Item
          label={t('Recieving Amount') + ' ' + transaction.get}
          value={transaction.get_sum}
        />

        <Item
          label={t('Exchange fee')}
          value={transaction.commission_sum + ' ' + transaction.give}
        />

        {transaction.tx && transaction.tx_url && (
          <Item
            label={t('TRX ID')}
            value={transaction.commission_sum + ' ' + transaction.give}
          >
            <a
              href={transaction.tx_url}
              target='_blank'
              className={cnTransactionDetail('link')}
            >
              {t('View explorer')}

              <Icon name='explorer' />
            </a>
            <div className={cnTransactionDetail('tx')}>{transaction.tx}</div>
          </Item>
        )}

        {transaction.tx && transaction.fee && (
          <Item label={t('Network fee')} value={transaction.fee} />
        )}
      </Items>

      {transaction && transaction.pay_url && (
        <div className={cnTransactionDetail('bottom')}>
          <Button
            className={cnTransactionDetail('btn')}
            size='xl'
            onClick={pay}
            icon={<Icon name='confirm' />}
            iconPosition='right'
          >
            {t('Confirm')}
          </Button>
        </div>
      )}
    </div>
  )
}
