import { cn } from '@bem-react/classname'
import useClickOutsideListener from '@patrissoljuns/react-click-outside/dist/useClickOutsideListener'
import { FC, ReactNode, useEffect, useState } from 'react'

import { Memo } from '@/hoc/Memo'

import { Icon } from '../Icon'
import './Dropdown.scss'

const cnDropdown = cn('Dropdown')

export interface IOptionDropdown {
  [key: string]: string | number
}

interface IDropdown {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[]
  className?: string
  classButton?: string
  classList?: string
  labelProp?: string
  valueProp?: string
  selectedProp?: string
  returned?: 'object' | 'value-prop' | 'label-prop'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: string | number | any
  icon?: ReactNode
  children?: ReactNode
  fillArrow?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (value: string | number | any) => void
}

export const Dropdown: FC<IDropdown> = Memo(
  ({
    options,
    className,
    classButton,
    classList,
    labelProp = 'label',
    valueProp = 'value',
    selectedProp = 'label',
    returned = 'object',
    value,
    icon,
    children,
    fillArrow = '#8A8A98',
    onClick,
  }: IDropdown) => {
    const [list, setList] = useState<IOptionDropdown[]>([])
    const [selected, setSelected] = useState<IOptionDropdown | null>(null)
    const [open, setOpen] = useState<boolean>(false)

    const ref = useClickOutsideListener<HTMLDivElement>({
      onClickOutside: () => setOpen(false),
      events: ['keydown', 'touchstart'],
    })

    const select = (value: IOptionDropdown) => {
      setOpen(false)
      setSelected(value)

      switch (returned) {
        case 'value-prop':
          onClick && onClick(value[valueProp])
          break
        case 'label-prop':
          onClick && onClick(value[labelProp])
          break

        default:
          onClick && onClick(value)
          break
      }
    }

    useEffect(() => {
      if (value && list.length) {
        switch (returned) {
          case 'value-prop':
          case 'label-prop':
            {
              const el = list.find(
                el => el[returned === 'value-prop' ? valueProp : labelProp],
              )

              if (el) {
                setSelected(el)
              }
            }

            break

          default:
            {
              const el = list.find(
                el => el[valueProp] === (value as IOptionDropdown)[valueProp],
              )

              if (el) {
                setSelected(el)
              }
            }
            break
        }
      }

      // if (!value && list.length) {
      //   const el = list[0]

      //   setSelected(el)
      //   select(el)
      // }
    }, [value, list])

    useEffect(() => {
      setList(options)
    }, [options])

    return (
      <div className={cnDropdown(null, [className])}>
        {selected && (
          <button
            type='button'
            className={cnDropdown('selected', [classButton])}
            onClick={() => setOpen(!open)}
            disabled={list.length === 1}
          >
            {icon ? (
              icon
            ) : (
              <>
                {selected[selectedProp]}
                {list.length > 1 && (
                  <Icon name='chevron-down' fill={fillArrow} />
                )}
              </>
            )}
          </button>
        )}

        {open && !children && list.length > 1 && (
          <div className={cnDropdown('list', [classList])} ref={ref}>
            {list.map((el, i) => (
              <button
                type='button'
                key={i}
                className={cnDropdown('item')}
                onClick={() => select(el)}
              >
                {el[labelProp]}
              </button>
            ))}
          </div>
        )}

        {open && children && (
          <div className={cnDropdown('list')}>{children}</div>
        )}
      </div>
    )
  },
)
