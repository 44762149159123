import { cn } from '@bem-react/classname'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'
import useWindowSize from '@/hooks/useWindowSize'

import { IUserVerify } from '@/modules/Auth/models/UserModel'
import { userSelector } from '@/modules/Auth/store/AuthSelector'
import {
  changeUserTypeThunk,
  getCurrentUserThunk,
} from '@/modules/Auth/store/AuthSlice'
import { getLastNotificationsThunk } from '@/modules/Notifications/store/notifications.slice'

import { Avatar } from '../Avatar'
import { Icon } from '../Icon'
import './Private.scss'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Menu } from './components/Menu'

interface IPrivate {
  children: ReactNode
}

const cnPrivate = cn('Private')

export const Private: FC<IPrivate> = Memo(({ children }: IPrivate) => {
  const { t } = useTranslation('translation')
  const { lessTablet } = useWindowSize()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [setBackendError] = useBackendError()
  const user = useAppSelector(userSelector)

  const [verify, setVerify] = useState<IUserVerify | null>(null)

  useEffect(() => {
    if (user) {
      switch (user.verify_status) {
        case 'none':
        case 'bad':
          setVerify({
            title: t('Get Level 1'),
            icon: 'status-danger',
            link: '/profile/verification',
          })
          break

        case 'init':
          setVerify({
            title: t('Verification process'),
            icon: 'status-warning',
          })
          break

        default:
          setVerify({
            title: t('Verificated'),
            icon: 'status-success',
          })
          break
      }
    }
  }, [user])

  const onClick = (link: string) => {
    navigate(link)
  }

  const getCurrentUser = async () => {
    try {
      await dispatch(getCurrentUserThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const getLastNotifications = async () => {
    try {
      await dispatch(getLastNotificationsThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  useEffect(() => {
    getCurrentUser()
    getLastNotifications()
  }, [])

  const changeUserType = async () => {
    try {
      await dispatch(changeUserTypeThunk()).unwrap()
      await getCurrentUser()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  return (
    <div className={cnPrivate()}>
      <Header />

      <div className={cnPrivate('content')}>
        {!lessTablet && (
          <div className={cnPrivate('sidebar')}>
            {user && (
              <div className={cnPrivate('top')}>
                <button
                  className={cnPrivate('user')}
                  onClick={() => onClick('/profile/settings')}
                >
                  <Avatar small src={user.avatar ? user.avatar : undefined} />
                </button>
                <div className={cnPrivate('info')}>
                  <button
                    className={cnPrivate('user')}
                    onClick={() => onClick('/profile/settings')}
                  >
                    <span className={cnPrivate('fullname')}>
                      {user.full_name ? user.full_name : t('Anonymous')}
                    </span>
                  </button>

                  {user &&
                    user.another_account &&
                    typeof user.another_account !== 'boolean' &&
                    user.another_account.type && (
                      <button
                        className={cnPrivate('type')}
                        onClick={changeUserType}
                      >
                        {t(user.another_account.type.title)}
                      </button>
                    )}
                </div>
              </div>
            )}

            {verify && (
              <button
                className={cnPrivate('button')}
                onClick={() => verify.link && onClick(verify.link)}
                disabled={!verify.link}
              >
                <Icon name={verify.icon} />
                {verify.title}
              </button>
            )}
            <Menu className={cnPrivate('menu')} />
          </div>
        )}

        {children}
      </div>

      {lessTablet && <Menu className={cnPrivate('mobile')} />}

      <Footer />
    </div>
  )
})
