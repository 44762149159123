import { FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Memo } from '@/hoc/Memo'

import { getItem } from '@/utils/persistanceStorage'

import { Private } from '@/components/Private'

export const PrivateRoute: FC = Memo(() => {
  const token = getItem('token', false)

  return token ? (
    <Private>
      <Outlet />
    </Private>
  ) : (
    <Navigate to={'/'} />
  )
})
