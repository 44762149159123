import { combineReducers } from '@reduxjs/toolkit'

import { reducer as appReducer } from '@/core/services/App/store/AppSlice'
import { reducer as exchangeReducer } from '@/core/services/Exchange/store/ExchangeSlice'
import { reducer as toastReducer } from '@/core/services/Toast/store/ToastSlice'
import { reducer as walletsReducer } from '@/core/services/Wallets/store/WalletsSlice'

import { reducer as authReducer } from '@/modules/Auth/store/AuthSlice'
import { reducer as notificationsReducer } from '@/modules/Notifications/store/notifications.slice'
import { reducer as transactionsReducer } from '@/modules/Transactions/store/TransactionsSlice'

const rootReducer = combineReducers({
  appReducer,
  toastReducer,
  walletsReducer,
  exchangeReducer,

  authReducer,
  transactionsReducer,
  notificationsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
