import { useEffect, useRef, useState } from 'react'

interface ITimer {
  timer: number
  time: string
  startTimer: (time: number) => void
  stopTimer: () => void
}

interface IProps {
  start?: number
  fps?: number
}

const useCountDown = ({ start, fps }: IProps): ITimer => {
  const [timer, setTimer] = useState<number>(start || 0)
  const [time, setTime] = useState<string>('')
  const intervalRef = useRef<NodeJS.Timer>()

  const stopTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current)
  }

  const startTimer = (time: number) => {
    setTimer(time)
  }

  useEffect(() => {
    if (timer <= 0) return stopTimer()
    intervalRef.current = setInterval(() => {
      setTimer(t => t - 1 / (fps || 1))
    }, 1000 / (fps || 1))
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [timer])

  useEffect(() => {
    if (timer > 0) {
      const minutes = parseInt(String(timer / 60), 10)
      const seconds = parseInt(String(timer % 60), 10)

      const m = minutes < 10 ? '0' + minutes : minutes
      const s = seconds < 10 ? '0' + seconds : seconds

      setTime(`${m}:${s}`)
    } else {
      setTime('')
    }
  }, [timer])

  return { timer, time, startTimer, stopTimer }
}

export default useCountDown
