import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { Memo } from '@/hoc/Memo'

import './styles/Item.scss'

const cnItem = cn('Item')

interface IItem
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label?: string
  value?: string | number
  full?: boolean
  children?: ReactNode
  small?: boolean
  mobile?: boolean
}

export const Item: FC<IItem> = Memo(
  ({ className, label, value, full, children, small, mobile }: IItem) => {
    return (
      <div
        className={cnItem(
          {
            full,
            small,
            mobile,
          },
          [className],
        )}
      >
        {label && <div className={cnItem('label', { small })}>{label}</div>}
        {value && <div className={cnItem('value')}>{value}</div>}
        {children}
      </div>
    )
  },
)
