import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

import { Memo } from '@/hoc/Memo'

import './Loading.scss'

const cnLoading = cn('Loading')

interface ILoading
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: 'small' | 'large' | 'lines'
  position?: 'center' | 'right' | 'left'
}

export const Loading: FC<ILoading> = Memo(
  ({ className, type = 'small', position }: ILoading) => {
    return (
      <>
        {type === 'large' && (
          <div
            className={cnLoading(
              {
                left: position === 'left',
                right: position === 'right',
                center: position === 'center',
              },
              [className],
            )}
          >
            <div className={cnLoading('circle')}></div>
            <div className={cnLoading('circle')}></div>
            <div className={cnLoading('circle')}></div>
          </div>
        )}

        {type === 'small' && (
          <div
            className={cnLoading(
              'small',
              {
                left: position === 'left',
                right: position === 'right',
                center: position === 'center',
              },
              [className],
            )}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_650_20446)'>
                <path
                  d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
                  stroke='url(#paint0_linear_650_20446)'
                  strokeWidth='4'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M12 0C5.37243 0 0 5.37243 0 12H4C4 7.58157 7.58157 4 12 4C13.1046 4 14 3.10457 14 2C14 0.89543 13.1046 0 12 0Z'
                  fill='#A0AEC0'
                />
              </g>
              <defs>
                <linearGradient
                  id='paint0_linear_650_20446'
                  x1='7.6308'
                  y1='14.748'
                  x2='16.9258'
                  y2='5.5566'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop offset='0.0001' stopColor='#A0AEC0' />
                  <stop offset='0.0002' stopColor='#A1AFC1' />
                  <stop offset='1' stopColor='white' stopOpacity='0' />
                </linearGradient>
                <clipPath id='clip0_650_20446'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}

        {type === 'lines' && (
          <div
            className={cnLoading(
              'lines',
              {
                left: position === 'left',
                right: position === 'right',
                center: position === 'center',
              },
              [className],
            )}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30px'
              height='30px'
              viewBox='0 0 100 100'
              preserveAspectRatio='xMidYMid'
            >
              <rect x='15' y='30' width='10' height='40' fill='#5244f3'>
                <animate
                  attributeName='opacity'
                  dur='1.7857142857142856s'
                  repeatCount='indefinite'
                  calcMode='spline'
                  keyTimes='0;0.5;1'
                  keySplines='0.5 0 0.5 1;0.5 0 0.5 1'
                  values='1;0.2;1'
                  begin='-1.0714285714285714'
                ></animate>
              </rect>
              <rect x='35' y='30' width='10' height='40' fill='#5244f3'>
                <animate
                  attributeName='opacity'
                  dur='1.7857142857142856s'
                  repeatCount='indefinite'
                  calcMode='spline'
                  keyTimes='0;0.5;1'
                  keySplines='0.5 0 0.5 1;0.5 0 0.5 1'
                  values='1;0.2;1'
                  begin='-0.7142857142857143'
                ></animate>
              </rect>
              <rect x='55' y='30' width='10' height='40' fill='#5244f3'>
                <animate
                  attributeName='opacity'
                  dur='1.7857142857142856s'
                  repeatCount='indefinite'
                  calcMode='spline'
                  keyTimes='0;0.5;1'
                  keySplines='0.5 0 0.5 1;0.5 0 0.5 1'
                  values='1;0.2;1'
                  begin='-0.35714285714285715'
                ></animate>
              </rect>
              <rect x='75' y='30' width='10' height='40' fill='#5244f3'>
                <animate
                  attributeName='opacity'
                  dur='1.7857142857142856s'
                  repeatCount='indefinite'
                  calcMode='spline'
                  keyTimes='0;0.5;1'
                  keySplines='0.5 0 0.5 1;0.5 0 0.5 1'
                  values='1;0.2;1'
                  begin='-1.7857142857142856'
                ></animate>
              </rect>
            </svg>
          </div>
        )}
      </>
    )
  },
)

// <svg
//   xmlns='http://www.w3.org/2000/svg'
//   width='46px'
//   height='46px'
//   viewBox='0 0 100 100'
//   preserveAspectRatio='xMidYMid'
// >
//   <defs>
//     <clipPath
//       id='progress-nietm0j2vri-cp'
//       x='0'
//       y='0'
//       width='100'
//       height='100'
//     >
//       <rect x='0' y='0' width='0' height='100'>
//         <animate
//           attributeName='width'
//           repeatCount='indefinite'
//           dur='1.7857142857142856s'
//           values='0;100;100'
//           keyTimes='0;0.5;1'
//         ></animate>
//         <animate
//           attributeName='x'
//           repeatCount='indefinite'
//           dur='1.7857142857142856s'
//           values='0;0;100'
//           keyTimes='0;0.5;1'
//         ></animate>
//       </rect>
//     </clipPath>
//   </defs>
//   <path
//     fill='none'
//     stroke='#5244f3'
//     strokeWidth='1'
//     d='M18 36L82 36A13.999999999999998 13.999999999999998 0 0 1 96 50L96 50A13.999999999999998 13.999999999999998 0 0 1 82 64L18 64A13.999999999999998 13.999999999999998 0 0 1 4 50L4 50A13.999999999999998 13.999999999999998 0 0 1 18 36 Z'
//   ></path>
//   <path
//     fill='#1c6fbb'
//     clipPath='url(#progress-nietm0j2vri-cp)'
//     d='M17.999999999999996 39.2L82 39.2A10.799999999999997 10.799999999999997 0 0 1 92.8 50L92.8 50A10.799999999999997 10.799999999999997 0 0 1 82 60.8L17.999999999999996 60.8A10.799999999999997 10.799999999999997 0 0 1 7.2 50L7.2 50A10.799999999999997 10.799999999999997 0 0 1 17.999999999999996 39.2 Z'
//   ></path>
// </svg>
