import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/core/store/store'

const transactions = (state: RootState) => state.transactionsReducer
export const transactionsSelector = createSelector(
  transactions,
  transactionsReducer => transactionsReducer.transactions,
)

const transaction = (state: RootState) => state.transactionsReducer
export const transactionSelector = createSelector(
  transaction,
  transactionsReducer => transactionsReducer.transaction,
)
