import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IResponseDefaultData } from '@/core/types'

import NotificationsService from '../NotificationsService'
import { INotification } from '../models/NotificationsModel'

export interface NotificationsState {
  notifications: IResponseDefaultData<INotification[]> | null
  count: number
  last: INotification[]
}

const initialState: NotificationsState = {
  notifications: null,
  count: 0,
  last: [],
}

export const getNotificationsThunk = createAsyncThunk(
  'notifications/getNotificationsThunk',
  async (page: number, { rejectWithValue }) => {
    try {
      return await NotificationsService.getNotifications(page)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const getLastNotificationsThunk = createAsyncThunk(
  'notifications/getLastNotificationsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await NotificationsService.getLastNotifications()
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const setViewNotificationsThunk = createAsyncThunk(
  'notifications/setViewNotificationsThunk',
  async (ids: number[], { rejectWithValue }) => {
    try {
      return await NotificationsService.setViewNotifications(ids)
    } catch (errors) {
      return rejectWithValue(errors)
    }
  },
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNotificationsThunk.fulfilled, (state, action) => {
        state.notifications = action.payload
      })

      .addCase(getNotificationsThunk.rejected, state => {
        state.notifications = null
      })

      .addCase(getLastNotificationsThunk.fulfilled, (state, action) => {
        state.count = action.payload.length
        state.last = action.payload
      })

      .addCase(getLastNotificationsThunk.rejected, state => {
        state.count = 0
        state.last = []
      })
  },
})

export const { actions, reducer } = notificationsSlice
