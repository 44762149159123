/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'

const useVefifyToken = (): { verify: boolean | null } => {
  const [isVerify, setVerify] = useState<boolean | null>(null)

  const verification = async (token: string) => {
    try {
      const currentDate = dayjs(new Date()).unix()
      const decode: any = await jwtDecode(token)

      if (decode) {
        if (currentDate <= decode.exp) {
          setVerify(true)
        } else {
          setVerify(false)
        }
      }
    } catch (error) {
      console.log(error)
      setVerify(false)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      verification(token)
    } else {
      setVerify(false)
    }
  }, [])

  return { verify: isVerify }
}

export default useVefifyToken
