import { cn } from '@bem-react/classname'
import queryString from 'query-string'
import { DetailedHTMLProps, FC, HTMLAttributes, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { usePagination } from '@/hooks/usePagination'

import { Icon } from '../Icon'
import './Pagination.scss'

const cnPagination = cn('Pagination')

interface IPagination
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  classNameList?: string
  total: number
  currentPage: number
  pageSize: number
  siblingCount?: number
  onPageChange: (page: number) => void
  position?: 'center' | 'right' | 'left'
}

export const Pagination: FC<IPagination> = ({
  className,
  classNameList,
  total,
  currentPage,
  siblingCount = 1,
  pageSize,
  onPageChange,
  position = 'center',
}) => {
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (search) {
      const { page } = queryString.parse(search)

      if (page) {
        onPageChange(+page)
      }
    }
  }, [search])

  const paginationRange = usePagination({
    currentPage,
    totalCount: total,
    siblingCount,
    pageSize,
  })

  if (
    currentPage === 0 ||
    (Array.isArray(paginationRange) && paginationRange.length < 2)
  ) {
    return null
  }

  const lastPage =
    Array.isArray(paginationRange) &&
    paginationRange[paginationRange.length - 1]

  return (
    <nav
      className={cnPagination(
        {
          center: position === 'center',
          right: position === 'right',
          left: position === 'left',
        },
        [className],
      )}
    >
      <ul className={cnPagination('list', [classNameList])}>
        {currentPage !== 1 && (
          <li className={cnPagination('first')}>
            <NavLink to={`${pathname}?page=1`}>
              <Icon name='paginate-left' />
            </NavLink>
          </li>
        )}

        {Array.isArray(paginationRange) &&
          paginationRange.map(pageNumber => {
            if (pageNumber + '' === '...') {
              return (
                <li
                  key={pageNumber}
                  className={cnPagination('item', { dots: true })}
                >
                  &#8230;
                </li>
              )
            }

            return (
              <li key={pageNumber} className={cnPagination('item')}>
                <NavLink
                  to={`${pathname}?page=${pageNumber}`}
                  className={cnPagination('link', {
                    active: pageNumber === currentPage,
                  })}
                >
                  {pageNumber}
                </NavLink>
              </li>
            )
          })}

        {currentPage !== lastPage && (
          <li className={cnPagination('first')}>
            <NavLink to={`${pathname}?page=${lastPage}`}>
              <Icon name='paginate-right' />
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  )
}
