import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { setToast } from '@/core/services/Toast/store/ToastSlice'
import { useAppDispatch } from '@/core/store/hooks'

export interface ErrorsScheme {
  value?: string | number
  field?: string
  code: number | string
  message: string
}

const useBackendError = (): [
  setBackendError: (errors: unknown) => void,
  fieldsError?: Record<string, ErrorsScheme>,
] => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('translation')
  const [errors, setErrors] = useState<ErrorsScheme[]>([])
  const [fieldsError, setFieldsError] = useState<Record<string, ErrorsScheme>>(
    {},
  )
  const [error, setError] = useState<ErrorsScheme[]>([])

  const convertArrayToObject = (array: ErrorsScheme[]) => {
    const initialValue = {}

    return array.reduce((obj, item) => {
      const key: string = item.field || String(item.code)
      return {
        ...obj,
        [key]: item,
      }
    }, initialValue)
  }

  const filterError = () => {
    const errorsGlobal: ErrorsScheme[] = []
    const fields: ErrorsScheme[] = []

    // console.log('filterError', error)

    error.forEach(el => {
      if (el && el.field && el.code !== -7 && el.code !== -1) {
        fields.push(el)
      } else {
        errorsGlobal.push(el)
      }
    })

    setErrors(errorsGlobal)
    setFieldsError(convertArrayToObject(fields))
  }

  const setBackendError = (errors: unknown): void => {
    console.log('setBackendError', errors)

    if (errors === null) {
      setError([])
      setFieldsError({})
      return
    }

    const { status, data } = errors as AxiosResponse

    if (status === 401) {
      setError([{ message: 'No authorization. Log in again.', code: '-30001' }])
      localStorage.removeItem('token')
      navigate('/')
      return
    }

    if (status === 400 && data) {
      if (data.bad_sum) {
        setError([{ message: 'Incorrect amount specified', code: '-30002' }])
      }

      if (data.max) {
        setError([
          {
            message: `The maximum amount should not exceed`,
            value: data.max,
            code: '-30003',
          },
        ])
      }

      if (data.min) {
        setError([
          {
            message: `The minimum amount must be at least`,
            value: data.min,
            code: '-30004',
          },
        ])
      }

      return
    }
  }

  useEffect(() => {
    filterError()
  }, [error])

  useEffect(() => {
    if (errors && errors.length > 0) {
      errors.forEach(el => {
        const message =
          String(el.code).indexOf('-3') !== -1
            ? t(`${el.message}`, { value: el.value })
            : t(`error${el.code}`)

        setTimeout(() => {
          dispatch(
            setToast({
              type: 'error',
              message: message,
            }),
          )
        }, 0)
      })
    }
  }, [errors])

  return [setBackendError, fieldsError]
}

export default useBackendError
