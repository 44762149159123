import { cn } from '@bem-react/classname'
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

import { Memo } from '@/hoc/Memo'

import './Empty.scss'

const cnEmpty = cn('Empty')

interface IEmpty
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
}

export const Empty: FC<IEmpty> = Memo(({ children, className }: IEmpty) => {
  return <div className={cnEmpty(null, [className])}>{children}</div>
})
